import React, { useEffect, useState } from 'react';
import styles from './CustomNavBar.module.css';
import logoImage from '../../assets/images/logo.webp';
import { GoHome } from "react-icons/go";
import { GoHomeFill } from "react-icons/go";
import { BsPlusSquare } from "react-icons/bs";
import { BsPlusSquareFill } from "react-icons/bs";
import { PiUserCircle } from "react-icons/pi";
import { PiUserCircleFill } from "react-icons/pi";
import { IoSettingsOutline } from "react-icons/io5";
import { IoSettings } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/UserContext';

const CustomNavBar = () => {
  // useNavigate
  const navigate = useNavigate();

  // useLocation
  const location = useLocation();
  const currentPage = location.pathname;

  // useState
  const [activePath, setActivePath] = useState('/');
  const [isLogined, setIsLogined] = useState(false);

  // useUser
  const { userData } = useUser();

  useEffect(() => {
    const activePaths = ['/', '/create-main', '/mypage', '/login', '/setting'];
    if (activePaths.includes(currentPage)) {
      setActivePath(currentPage);
    }

    if (userData) {
      setIsLogined(true);
    } else {
      setIsLogined(false);
    }
  }, [currentPage, userData]);

  const isActive = (path) => path === activePath;

  const handleClick = (path) => {
    if(path===currentPage){
      navigate(`${path}?update=${Date.now()}`);
    }
    else{
      navigate(path);
    }

  }

  return (
    <div className={styles.nav_bar_page}>
      <div className={styles.nav_bar_header}>
        <div className='blank-30' />
        < img className={styles.nav_bar_header_logo} src={logoImage} alt='logo' />
        <div style={{ flex: 1 }} />
        {userData && userData.isAdmin ? <p className={styles.nav_bar_header_text}>관리자</p> : ''}
        <div className='blank-30' />
      </div>
      <div className={styles.nav_bar_body}>
        < img className={styles.nav_logo} src={logoImage} alt='logo' onClick={() => { handleClick('/') }}/>
        <div className={styles.nav_item_container}>
          <div type="button" className={styles.nav_item} style={{ marginLeft: 0 }} onClick={() => { handleClick('/') }}>
            {isActive('/') ? <GoHomeFill size={36} /> : <GoHome size={36} />}
            <p className={styles.nav_item_text}>홈</p>
          </div>
          {userData && userData.isAdmin ? <div type="button" className={styles.nav_item} onClick={() => { handleClick('/create-main') }}>
            {isActive('/create-main') ? <BsPlusSquareFill strokeWidth={0.2} size={28} />
              : <BsPlusSquare strokeWidth={0.2} size={28} />}
            <p className={styles.nav_item_text}>만들기</p>
          </div> : null}
          {
            isLogined ?
              <div type="button" className={styles.nav_item} onClick={() => { handleClick('/mypage') }}>
                <img className={styles.nav_item_profile} src={userData ? userData.profile : ''} alt='' />
                <p className={styles.nav_item_text}>{`프로필 ${userData ? userData.isAdmin ? '(관리자)' : '' : ''}`}</p>
              </div>
              :
              <div type="button" className={styles.nav_item} style={{ marginLeft: 0 }} onClick={() => { handleClick('/login') }}>
                {isActive('/login') ? <PiUserCircleFill size={35} /> : <PiUserCircle size={35} />}
                <p className={styles.nav_item_text}>로그인</p>
              </div>
          }


          <div type="button" id={styles.last_nav_item} className={styles.nav_item}
            style={{ marginLeft: 0 }} onClick={() => { handleClick('/setting') }}>
            {isActive('/setting') ? <IoSettings size={32} /> : <IoSettingsOutline size={32} />}
            <p className={styles.nav_item_text}>설정</p>
          </div>
        </div>
      </div >
    </div>

  );
}

export default CustomNavBar;