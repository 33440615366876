import React, { useEffect, useState } from 'react';
import styles from './SurveyEssay.module.css';

const SurveyEssay = ({ data, num, leftNum, onClickPrevBtn, onClickEvent, answers }) => {
    const [isContentFilled, setIsContentFilled] = useState(false);
    const [answer, setAnswer] = useState('');

    useEffect(() => {
        setAnswer(answers[data.id] || '');
        if(answers[data.id]!==null){
            setIsContentFilled(true);
        }else{
            setIsContentFilled(false);
        }
    }, [answers, data.id]);

    return (
        <div className={styles.container}>
            <div className={styles.num_container}>
                <p className={styles.num}>{`Q${num+1}`}</p>
                <div className={styles.blank} />
                <p className={styles.left_num}>{`${leftNum}개 남음`}</p>
            </div>
            <div className='blank-20' />
            <p className={styles.title}>{data.title}</p>
            <div className='blank-50' />
            <form>
                <textarea
                    className={styles.answer_input}
                    placeholder="답변을 적어주세요."
                    value={answer}
                    onChange={(e) => {
                        const newValue = e.target.value;
                        setAnswer(newValue);
                        setIsContentFilled(newValue === '' ? false : true);
                    }}
                />
                <div className='blank-20' />
                <div className={styles.btn_container}>
                    {num === 0 || <button
                        type='button'
                        className={styles.prev_btn}
                        onClick={() => onClickPrevBtn(data.id, answer)}>
                        이전
                    </button>}
                    {num === 0 || <div className='blank-20' />}
                    {<button
                        type='button'
                        className={styles.next_btn}
                        style={{ opacity: !isContentFilled ? 0.2 : 1 }}
                        onClick={() => {
                            if (isContentFilled) {
                                onClickEvent(data.id, answer);
                            }
                        }}
                        disabled={!isContentFilled}
                        aria-disabled={isContentFilled ? "true" : "false"}>
                        {leftNum !== 0 ? "다음" : "제출하기"}
                    </button>}
                </div>
            </form>
        </div>
    )
}

export default SurveyEssay;