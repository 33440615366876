import React from "react";
import styles from "./IndividualMultipleChoiceView.module.css";
import { FaCheckCircle } from 'react-icons/fa';

const IndividualMultipleChoiceView = ({ currentNum, multipleChoice, multipleChoiceAnswers, myMultipleChoiceAnswer }) => {
    // multipleChoice<Object>
    // multipleChoiceAnswers<Array<int>>

    let optionCounts = { 0: 0, 1: 0, 2: 0, 3: 0, 4: 0 };
    let totalCount = 0;
    multipleChoiceAnswers.forEach(answer => {
        optionCounts[answer] += 1;
        totalCount += 1;
    });

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                <div className={styles.number_container}>
                    <p className={styles.number}>
                        {`Q${currentNum}`}
                    </p>
                </div>
                <div className='blank-20' />
                <p className={styles.title}>{multipleChoice.title}</p>
                <div className='blank-20' />
                {multipleChoice.options.map((option, i) => {
                    return (
                        <div key={option.id}>
                            <div className={styles.option_container}>
                                <FaCheckCircle
                                    className={styles.checkbox_icon}
                                    size={24}
                                    style={{ opacity: myMultipleChoiceAnswer === i ? 1 : 0.2 }}
                                />
                                <div className='blank-10'/>
                                {option.image !== '' ? <img className={styles.option_image} src={option.image} alt={option.content} /> :
                                    <div className={styles.option_image_blank} />}
                                <div className={styles.option_details}>
                                    <div className={styles.option_contentRow}>
                                        <p className={styles.option_text}>{option.content}</p>
                                        <div style={{ flex: 1 }}></div>
                                        <p className={styles.option_text}>
                                            {`${Math.floor((optionCounts[i] / totalCount) * 100)}% (${optionCounts[i]})`}
                                        </p>
                                    </div>
                                    <div>
                                        <div className='blank-10' />
                                        <div className={styles.option_progressBar_container}>
                                            <div className={styles.option_progress_bar} style={{
                                                width: `${Math.floor((optionCounts[i] / totalCount) * 100)}%`,
                                                opacity: `${optionCounts[i] >= totalCount / 2 ? 1 : 0.2}`
                                            }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default IndividualMultipleChoiceView;