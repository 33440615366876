import React, { createContext, useContext, useState } from "react";
import { Response } from '../model/response/response';

const AnswerContext = createContext();

export const AnswerProvider = ({ children }) => {
    const [answerObjs, setAnswerObjs] = useState({});
    const [response] = useState(() => new Response());

    // postId로 answers<Array<string>> 불러오기
    const getAnswers = async (post) => {
        if (answerObjs[post.id]) {
            return answerObjs[post.id];
        } else {
            const newAnswers = await response.readAllAnswers(post);
            setAnswerObjs({
                ...answerObjs, ...{
                    [post.id]: newAnswers
                }
            });
            return newAnswers;
        }
    }

    return (
        <AnswerContext.Provider value={{ getAnswers }}>
            {children}
        </AnswerContext.Provider>
    );
};

export const useAnswers = () => useContext(AnswerContext);