import React from "react";
import styles from "./IndividualEssayView.module.css";

const IndividualEssayView = ({ currentNum, essay, essayAnswer }) => {
    // essay<Object>
    // essayAnswers<Array<string>>

    return (
        <div className={styles.container}>
            <div className={styles.number_container}>
                <p className={styles.number}>
                    {`Q${currentNum}`}
                </p>
            </div>
            <div className='blank-20' />
            <p className={styles.title}>
                {essay.title}
            </p>
            <div className='blank-50' />
            <div className={styles.answer_section}>
                <div className={styles.answer_container}>
                    <div className={styles.answer_text_container}>
                        <p className={styles.answer_text}>
                            {essayAnswer}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default IndividualEssayView;