// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { UserProvider } from './contexts/UserContext';
import { PostProvider } from './contexts/PostContext';
import Main from './pages/Main';
import SignUp from './pages/login/SignUp';
import Login from './pages/login/Login';
import ConsentForm from './pages/login/ConsentForm';
import DocumentTerm from './pages/login/DocumentTerm';
import DocumentPrivacy from './pages/login/DocumentPrivacy';
import Welcome from './pages/login/Welcome';
import Survey from './pages/Survey';
import Complete from './pages/Complete';
import CreateComplete from './pages/create_post/CreateComplete';
import CreateVote from './pages/create_post/CreateVote';
import CreateSurvey from './pages/create_post/CreateSurvey';
import CreateMain from './pages/create_post/CreateMain';
import Setting from './pages/Setting';
import MyPage from './pages/MyPage';
import Analytics from './pages/data_analytics/Analytics';
import AnalyticsMain from './pages/data_analytics/AnalyticsMain';
import { AnswerProvider } from './contexts/AnswerContext';
import SurveyTotalView from './pages/data_analytics/SurveyTotalView';
import SurveyIndividualView from './pages/data_analytics/SurveyIndividualView';
import EssayTotalView from './pages/data_analytics/EssayTotalView';
import Layout from './component/layout/Layout';
import { NavigationProvider } from './contexts/NavigationContext';

function App() {
  return (
    <UserProvider>
      <PostProvider>
        <AnswerProvider>
          <NavigationProvider>
            <Router>
              <Layout>
                <Routes>
                  <Route path='/' element={<Main />} />
                  <Route path='/mypage' element={<MyPage />} />
                  <Route path='/setting' element={<Setting />} />
                  <Route path='/login' element={<Login />} />
                  <Route path='/signup' element={<SignUp />} />
                  <Route path='/consentform' element={<ConsentForm />} />
                  <Route path='/document-term' element={<DocumentTerm />} />
                  <Route path='/document-privacy' element={<DocumentPrivacy />} />
                  <Route path='/welcome' element={<Welcome />} />
                  <Route path='/survey' element={<Survey />} />
                  <Route path='/complete' element={<Complete />} />
                  <Route path='/create-main' element={<CreateMain />} />
                  <Route path='/create-vote' element={<CreateVote />} />
                  <Route path='/create-survey' element={<CreateSurvey />} />
                  <Route path='/create-complete' element={<CreateComplete />} />
                  <Route path='/analytics-main' element={<AnalyticsMain />} />
                  <Route path='/analytics' element={<Analytics />} />
                  <Route path='/survey-total-view' element={<SurveyTotalView />} />
                  <Route path='/survey-individual-view' element={<SurveyIndividualView />} />
                  <Route path='/essay-total-view' element={<EssayTotalView />} />
                  <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
              </Layout>
            </Router>
          </NavigationProvider>
        </AnswerProvider>
      </PostProvider>
    </UserProvider>
  );
}

export default App;
