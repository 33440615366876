// ✅ GPT 코드리뷰
import React, { useEffect } from 'react';
import styles from './Login.module.css';
import logoImage from '../../assets/images/logo.webp';
import kakaoSymbolImage from '../../assets/images/kakao_symbol.png';
import { Response } from '../../model/response/response';
import { useNavigate } from 'react-router-dom';
import { useUser } from './../../contexts/UserContext';
import { User } from '../../model/response/user';
import { useNavigation } from '../../contexts/NavigationContext';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';


const Login = () => {
    const { isInternalNavigation, setIsInternalNavigation } = useNavigation();

    // useUser 초기화
    const { initializeUserData } = useUser();

    // Response 초기화
    const response = new Response();

    // UseNavigate 초기화
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInternalNavigation) setIsInternalNavigation(true);

        // SDK 초기화
        if (!window.Kakao.isInitialized()) {
            window.Kakao.init(process.env.REACT_APP_KAKAO_JAVASCRIPT_KEY);
        }
    }, [isInternalNavigation, setIsInternalNavigation]);

    const handleLogin = () => {
        window.Kakao.Auth.login({
            success: async function () {
                try {
                    let kakaoUser;

                    // 사용자 정보 가져오기
                    await window.Kakao.API.request({
                        url: '/v2/user/me',
                        success: function (res) {
                            kakaoUser = res;
                        },
                        fail: function (error) {
                            console.error('사용자 정보 가져오기 실패', error);
                        },
                    });

                    let newUser = await response.readUser(kakaoUser.id.toString());
                    if (newUser != null) {
                        const email = `${newUser.uid}@gmail.com`;
                        const password = "ehdtjskaqnr"; // newUser.uid를 암호화한 비밀번호

                        try {
                            signInWithEmailAndPassword(auth, email, password)
                                .then(() => {
                                    // 로그인 성공
                                    // localStorage uid 저장
                                    localStorage.setItem('uid', newUser.uid);
                                    // UserProvider userData 저장
                                    initializeUserData(User.fromJson(newUser));
                                    navigate('/', { replace: true, state: newUser });
                                })
                                .catch((error) => {
                                    // 로그인 실패. 사용자를 회원가입 페이지로 이동
                                    console.error('로그인 실패:', error);
                                    navigate('/signup', {
                                        state: {
                                            kakaoUser: kakaoUser
                                        }
                                    });
                                });
                        } catch (e) {
                            console.error(e);
                        }

                    } else {
                        try {
                            navigate('/signup', {
                                state: {
                                    kakaoUser: kakaoUser
                                }
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    }
                } catch (e) {
                    console.log(e);
                }

            },
            fail: function (err) {
                // 로그인 실패 시 처리
                console.error('로그인 실패', err);
            },
        });
    };

    return (
        <div className={styles.page}>
            <img className={styles.logo_image} src={logoImage} alt='logo' />
            <p className={styles.title}>로그인</p>
            <div className='blank-20' />
            <p className={styles.description}>카카오톡으로 간단하게</p>
            <p className={styles.description}>아이디와 비밀번호 입력 없이 로그인 할 수 있어요</p>
            <div className='blank-100' />
            <div className={styles.kakao_login_btn} onClick={handleLogin}>
                <div className={styles.kakao_login_btn_inner}>
                    <img className={styles.kakao_login_btn_inner_image} src={kakaoSymbolImage} alt='kakao symbol' />
                    <div className={styles.kakao_login_btn_inner_text_container}>
                        <p className={styles.kakao_login_btn_inner_text}>카카오 로그인</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;
