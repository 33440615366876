import React, { useState, useEffect } from "react";
import styles from './CreateSurvey.module.css';
import { useUser } from '../../contexts/UserContext';
import { usePosts } from '../../contexts/PostContext';
import { useNavigate } from "react-router-dom";
import SurveyThumbnail from "../../component/create/SurveyThumbnail";
import ChoiceType from "../../component/create/ChoiceType";
import SurveyEssay from "../../component/create/SurveyEssay";
import SurveyMc from "../../component/create/SurveyMc";
import { Response } from '../../model/response/response';
import { v4 as uuidv4 } from 'uuid';
import { useNavigation } from "../../contexts/NavigationContext";

const CreateSurvey = () => {
    const { isInternalNavigation } = useNavigation();
    // navigate
    const navigate = useNavigate();
    // userProvider
    const { userData } = useUser();
    // postProvider
    const { createPost } = usePosts();
    // response
    const response = new Response();
    // useState
    const [isLoading, setIsLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [thumbnailImage, setThumbnailImage] = useState('');
    const [questionIds, setQuestionIds] = useState([]);
    const [essays, setEssays] = useState([]);
    const [multipleChoices, setMultipleChoices] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalIndex, setTotalIndex] = useState(0);

    useEffect(() => {
        if (!isInternalNavigation){
            navigate('/', {
                replace: true
            });
        }
    }, [navigate, isInternalNavigation])

    // handleThumbnail
    const handleThumbnail = (title, imageUrl) => {
        setTitle(title);
        setThumbnailImage(imageUrl);
        setTotalIndex(totalIndex + 1);
        setCurrentIndex(currentIndex + 1);
    }

    // handleType
    const handleType = (type) => {
        const countEssays = questionIds.filter(item => item.includes('essay')).length;
        const countMultipleChoices = questionIds.filter(item => item.includes('mc')).length;

        let newQuestionIds = [...questionIds];

        if (type === "essay") {
            const myIndex = countEssays + 1;
            let newEssays = [...essays];
            newEssays.push({ id: `essay-${myIndex}`, title: '', type: 'essay' });
            setEssays(newEssays);
            newQuestionIds.push(`essay-${myIndex}`);
        } else {
            const myIndex = countMultipleChoices + 1;
            let newMultipleChoices = [...multipleChoices];
            newMultipleChoices.push({
                id: `mc-${myIndex}`, title: '', type: 'multipleChoice', options: [
                    { content: '', image: '', id: uuidv4() },
                    { content: '', image: '', id: uuidv4() },
                ]
            })
            setMultipleChoices(newMultipleChoices);
            newQuestionIds.push(`mc-${myIndex}`);
        }

        setQuestionIds(newQuestionIds);
    }

    // onClickPrevBtn
    const onClickPrevBtn = () => {
        setCurrentIndex(currentIndex - 1);
    }

    // onClickNextBtn
    const onClickNextBtn = () => {
        setCurrentIndex(currentIndex + 1);
    }

    // handleEssay
    const handleEssay = (title) => {
        const currentEssayIndex = essays.findIndex((item) => item.id === questionIds[currentIndex - 1]);
        let newEssays = [...essays];
        newEssays[currentEssayIndex].title = title;
        setEssays(newEssays);
    }

    // handleMc
    const handleMc = (updateElement, element) => {
        let newMultipleChoices = [...multipleChoices];
        const currentMcIndex = multipleChoices.findIndex((item) => item.id === questionIds[currentIndex - 1]);

        if (updateElement === 'title') {
            newMultipleChoices[currentMcIndex].title = element;
        } else if (updateElement === 'options') {
            newMultipleChoices[currentMcIndex].options = element;
        }

        setMultipleChoices(newMultipleChoices);
    }

    // handleAddQuestion
    const handleAddQuestion = () => {
        const newTotalNum = totalIndex + 1;
        setTotalIndex(newTotalNum);
        setCurrentIndex(newTotalNum);
    }

    // handleDeleteQuestion
    const handleDeleteQuestion = () => {
        const questionId = questionIds[currentIndex - 1];

        // questionId 유형 확인 (객관식: 'mc', 주관식: 'essay')
        const isMc = questionId.startsWith('mc');
        const questionType = isMc ? 'mc' : 'essay';
        const listToUpdate = isMc ? [...multipleChoices] : [...essays];

        // newQuestionIds
        let newQuestionIds = [...questionIds];

        // 삭제할 질문의 인덱스 찾기
        const deleteIndex = listToUpdate.findIndex(question => question.id === questionId);
        if (deleteIndex === -1) return; // 찾지 못한 경우 함수 종료

        // questionIds와 해당 질문 리스트에서 질문 삭제
        listToUpdate.splice(deleteIndex, 1);
        const indexInQuestionIds = newQuestionIds.indexOf(questionId);
        newQuestionIds.splice(indexInQuestionIds, 1);

        // 해당 유형의 나머지 질문들의 id 재정렬
        listToUpdate.forEach((question, index) => {
            const newIndex = index + 1;
            const newId = `${questionType}-${newIndex}`;
            const oldId = question.id;

            // questionIds에서도 변경 사항 반영
            const questionIdIndex = newQuestionIds.indexOf(oldId);
            if (questionIdIndex !== -1) {
                newQuestionIds[questionIdIndex] = newId;
            }

            // 질문 객체의 id 업데이트
            question.id = newId;
        });

        if (isMc) {
            setMultipleChoices(listToUpdate);
        } else {
            setEssays(listToUpdate);
        }

        setQuestionIds(newQuestionIds);

        setTotalIndex(totalIndex - 1);

        if (currentIndex === totalIndex) {
            setCurrentIndex(currentIndex - 1);
        }
    }

    const isAllFilled = () => {
        const isTitleFilled = title !== '';
        const isThumbnailImageFilled = thumbnailImage !== '';
        const isEssaysFilled = essays.every((item) => item.title !== '');
        const isMcFilled = multipleChoices.every((item) => item.title !== '' &&
            item.options.every((option) => option.content !== ''));

        return (isTitleFilled && isThumbnailImageFilled && isEssaysFilled && isMcFilled);
    }

    const handleSubmit = async () => {
        if (isLoading) return;
        setIsLoading(true);

        if (!isAllFilled()) {
            alert('내용을 모두 채워주세요');
            setIsLoading(false);
            return;
        }

        try {
            // 타이틀 이미지 업로드
            let thumbnailImageUrl = '';
            if (thumbnailImage !== '') {
                thumbnailImageUrl = await response.uploadImageAndGetURL(thumbnailImage);
            }

            // multipleChoices의 모든 옵션에 있는 사진들 업로드
            let newMultipleChoices = await Promise.all(multipleChoices.map(async (choice) => {
                const newOptions = await Promise.all(choice.options.map(async (option) => {
                    if (option.image !== '') {
                        const newImageUrl = await response.uploadImageAndGetURL(option.image);
                        return { ...option, image: newImageUrl !== null ? newImageUrl : '' };
                    }
                    return option;
                }));
                return { ...choice, options: newOptions };
            }));

            // 모든 이미지 업로드 후 설문조사 생성
            const date = new Date().toISOString();
            const newSurvey = {
                id: `survey-${date}`,
                date: date,
                type: 'survey',
                ownerUid: userData.uid,
                title: title,
                thumbnailImage: thumbnailImageUrl, // 업로드된 타이틀 이미지 URL 사용
                questionIds: questionIds,
                essays: essays,
                multipleChoices: newMultipleChoices,
                totalCount: 0,
                answerIds: [`survey-${date}-1`]
                // postIdIdsNum은 response에서 추가
            }

            await createPost(newSurvey);

            // navigate Complete page
            navigate('/create-complete', {
                replace: true
            });
        } catch (e) {
            console.error(e);
            alert('설문조사 생성 중 오류가 발생했습니다.');
        } finally {
            setIsLoading(false);
        }
    }


    const isTimeToChoiceType = () => {
        return (questionIds.length !== totalIndex);
    }

    const getComponent = () => {
        if (totalIndex === 0) {
            return (<SurveyThumbnail currentTitle={title} currentImage={thumbnailImage} notifyParent={handleThumbnail} />);
        } else if (isTimeToChoiceType()) {
            return (<ChoiceType notifyParent={handleType} />);
        } else {
            if (questionIds[currentIndex - 1].startsWith('essay')) {
                const currentEssayIndex = essays.findIndex((item) => item.id === questionIds[currentIndex - 1]);

                return (<SurveyEssay
                    currentTitle={essays[currentEssayIndex].title}
                    currentNum={currentIndex}
                    isPrevExist={currentIndex !== 1}
                    isNextExist={currentIndex !== totalIndex}
                    notifyParent={handleEssay}
                    onClickPrevBtn={onClickPrevBtn}
                    onClikNextBtn={onClickNextBtn}
                    onClickDeleteBtn={handleDeleteQuestion}
                />);
            } else {
                const currentMcIndex = multipleChoices.findIndex((item) => item.id === questionIds[currentIndex - 1]);

                return (<SurveyMc
                    currentTitle={multipleChoices[currentMcIndex].title}
                    currentOptions={multipleChoices[currentMcIndex].options}
                    currentNum={currentIndex}
                    isPrevExist={currentIndex !== 1}
                    isNextExist={currentIndex !== totalIndex}
                    notifyParent={handleMc}
                    onClickPrevBtn={onClickPrevBtn}
                    onClikNextBtn={onClickNextBtn}
                    onClickDeleteBtn={handleDeleteQuestion}
                />);
            }
        }
    }

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>설문조사 만들기</p>
            <div className="blank-30"/>
            {getComponent()}
            <div className={styles.content_container}>
            </div>
            {(currentIndex === 0 || isTimeToChoiceType()) || <div className="blank-50" />}
            {(currentIndex === 0 || isTimeToChoiceType()) || <button onClick={handleAddQuestion} className={styles.add_question_btn} type="button">
                <p className={styles.add_question_btn_text}>질문 추가하기</p>
            </button>}
            {(currentIndex === 0 || isTimeToChoiceType()) || <div className="blank-20" />}
            {(currentIndex === 0 || isTimeToChoiceType()) || <button onClick={handleSubmit} className={styles.submit_btn} type="button">
                <p className={styles.submit_btn_text} style={!isLoading ? {} : { display: 'none' }}>업로드하기</p>
                <div className={styles.loader} style={isLoading ? {} : { display: 'none' }}></div>
            </button>}
            <div className="blank-50"/>
        </div>
    )
}

export default CreateSurvey;