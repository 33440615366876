// Import necessary functions and the db instance from firebase.js
import { db, storage } from "../../firebase.js";
import {
  collection,
  setDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  arrayUnion,
  arrayRemove,
  increment
} from "firebase/firestore";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

export class Response {
  userCollectionRef = collection(db, "users");
  postCollectionRef = collection(db, "posts");
  postIdsCollectionRef = collection(db, "postIds");
  statusCollectionRef = collection(db, "status");
  resultCollectionRef = collection(db, "results");

  // User
  async createUser(data) {
    try {
      // Use doc to get a DocumentReference with the specified UID
      const docRef = doc(this.userCollectionRef, data.uid);
      // Use setDoc to create or overwrite the document with the provided data
      await setDoc(docRef, data);
      return true;
    } catch (e) {
      console.error("Error adding document: ", e);

      return false;
    }
  }

  async readUser(uid) {
    try {
      const docRef = doc(this.userCollectionRef, uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data(); // Returning the document data
      } else {
        return null; // Indicating no document found
      }
    } catch (e) {
      console.error("Error reading document: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async readAllUsers() {
    try {
      const querySnapshot = await getDocs(this.userCollectionRef);
      const documents = [];
      querySnapshot.forEach((doc) => {
        documents.push(doc.data());
      });
      return documents; // Returning all documents data
    } catch (e) {
      console.error("Error reading documents: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async updateUser(uid, updatedData) {
    try {
      const docRef = doc(this.userCollectionRef, uid);
      await updateDoc(docRef, updatedData);
    } catch (e) {
      console.error("Error updating document: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async deleteUser(uid) {
    try {
      const docRef = doc(this.userCollectionRef, uid);
      await deleteDoc(docRef);
    } catch (e) {
      console.error("Error deleting document: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  // PostIds
  sortObjectKeysByDate(postIdsObj) {
    const sortedKeys = Object.keys(postIdsObj).sort((a, b) => {
      // 날짜 문자열 추출
      const dateStrA = a.split('-').slice(1).join('-');
      const dateStrB = b.split('-').slice(1).join('-');
      // Date 객체로 변환
      const dateA = new Date(dateStrA);
      const dateB = new Date(dateStrB);
      // 내림차순 정렬
      return dateB - dateA;
    });

    const sortedPostIdsObj = {};
    sortedKeys.forEach(key => {
      sortedPostIdsObj[key] = postIdsObj[key];
    });
    return sortedPostIdsObj;
  }

  async readAllPostIds() {
    try {
      const querySnapshot = await getDocs(this.postIdsCollectionRef);
      let postIdsObj = {}; // 객체로 초기화
      if (querySnapshot) {
        querySnapshot.forEach((doc) => {
          const postIdsArray = doc.data().postIds; // 현재 문서의 postIds 배열
          // postIds 배열의 각 요소를 객체의 키로 사용하고, 값으로 false를 할당
          postIdsArray.forEach(postId => {
            postIdsObj[postId] = false;
          });
        });
      }

      return this.sortObjectKeysByDate(postIdsObj); // 객체 반환
    } catch (e) {
      console.error("Error reading documents: ", e);
      throw e; // 에러 다시 던지기
    }
  }

  // Post
  async createPost(data) {
    try {
      // 게시물 생성이 성공하면, 'postIds' 컬렉션에 새 문서를 생성합니다.
      // 현재 postIds 문서 num를 가져옵니다.
      const currentPostIdsNumDocRef = doc(this.statusCollectionRef, 'currentPostIdsNum');
      const currentPostIdsDocSnap = await getDoc(currentPostIdsNumDocRef);
      let currentPostIdsNum = 0;
      if (currentPostIdsDocSnap.exists()) {
        currentPostIdsNum = currentPostIdsDocSnap.data().num;
      }

      // 현재 postIds 문서에 값이 15,000개가 넘었는지 확인하고, postId를 업로드합니다.
      const postIdsDocRef = doc(this.postIdsCollectionRef, `postIds-${currentPostIdsNum}`);
      const postIdsDocSnap = await getDoc(postIdsDocRef);
      let postIds = [];
      if (postIdsDocSnap.exists()) {
        postIds = postIdsDocSnap.data().postIds;

        // 만약 15,000개가 넘었다면
        if (postIds.length >= 15000) {
          currentPostIdsNum++;
          const newPostIdsDocRef = doc(this.postIdsCollectionRef, `postIds-${currentPostIdsNum}`);
          await setDoc(newPostIdsDocRef, { postIds: [data.id] });
          await setDoc(currentPostIdsNumDocRef, { num: currentPostIdsNum });
        } else {
          await updateDoc(postIdsDocRef, {
            // 새로운 postId를 추가합니다.
            postIds: arrayUnion(data.id)
          });
        }
      }

      const newPost = { ...data, postIdsNum: currentPostIdsNum };
      const postDocRef = doc(this.postCollectionRef, data.id); // 게시물에 대한 DocumentReference를 생성합니다.
      await setDoc(postDocRef, newPost); // 'posts' 컬렉션에 게시물 데이터를 저장합니다.

      // 게시물 생성이 성공하면, 'results' 컬렉션에 새 문서를 생성합니다.
      const resultsDocRef = doc(this.resultCollectionRef, data.answerIds[0]); // 'results' 컬렉션 내에 동일한 ID를 사용하여 DocumentReference를 생성합니다.
      await setDoc(resultsDocRef, { id: data.answerIds[0], answers: [] }); // 'results' 컬렉션에 문서를 생성하거나 덮어씁니다.

      return newPost;
    } catch (e) {
      console.error("Error adding document: ", e);
      throw e; // 에러를 재발생시켜 외부에서 처리할 수 있게 합니다.
    }
  }

  async readPost(id) {
    try {
      const docRef = doc(this.postCollectionRef, id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        return docSnap.data(); // Returning the document data
      } else {
        return null; // Indicating no document found
      }
    } catch (e) {
      console.error("Error reading document: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async readAllPosts() {
    try {
      const querySnapshot = await getDocs(this.postCollectionRef);
      const documents = [];
      querySnapshot.forEach((doc) => {
        documents.push(doc.data());
      });
      return documents; // Returning all documents data
    } catch (e) {
      console.error("Error reading documents: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async incrementPost(id, incrementInfos) {
    try {
      const docRef = doc(this.postCollectionRef, id);
      let updates = {};
      Object.keys(incrementInfos).forEach(key => {
        updates[key] = increment(incrementInfos[key]);
      });

      // 모든 필드 업데이트를 한 번의 updateDoc 호출로 적용합니다.
      await updateDoc(docRef, updates);
    } catch (e) {
      console.error("Error updating document: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async updatePost(id, updatedData) {
    try {
      const docRef = doc(this.postCollectionRef, id);
      await updateDoc(docRef, updatedData);
    } catch (e) {
      console.error("Error updating document: ", e);
      throw e; // Re-throwing the error for handling it outside
    }
  }

  async deletePostId(post) {
    try {
      const docRef = doc(this.postIdsCollectionRef, `postIds-${post.postIdsNum}`);
      await updateDoc(docRef, {
        // 조건에 맞는 항목을 제거합니다.
        postIds: arrayRemove(post.id),
      });
    } catch (e) {
      console.error("Error deleting document: ", e);
      throw e;
    }
  }

  // 최신 게시물 15개를 불러오는 함수
  async loadPosts(postIds, participatedPostIds, isLastPost, type, isOnlyParticipatedPosts) {
    try {

      // type과 isParticipatedPosts에 따라 필터링한 새로운 postIds
      const newPostIds = [];
      Object.keys(postIds).some((key) => {
        const postId = key;
        // post를 불러오지 않은 post id인지 검사
        if (!postIds[key]) {
          // type에 맞는지 검사
          let typeSpecificPostId = null;
          if (type === 0) {
            typeSpecificPostId = postId;
          } else if (type === 1) {
            if (postId.startsWith('vote-')) {
              typeSpecificPostId = postId;
            }
          } else {
            if (postId.startsWith('survey-')) {
              typeSpecificPostId = postId;
            }
          }

          // type에 맞는 post id인지 검사
          if (typeSpecificPostId) {
            // 내가 참여한 질문인지 검사
            if (isOnlyParticipatedPosts) {
              participatedPostIds.forEach(item => {
                if (item === typeSpecificPostId) {
                  newPostIds.push(typeSpecificPostId);
                }
              })
            } else {
              newPostIds.push(typeSpecificPostId);
            }

            if (newPostIds.length === 15) {
              return true;
            }
          }
        }
        return false;
      })

      // 새로운 post들 불러오기
      const newPostsPromises = newPostIds.map(postId => this.readPost(postId));
      const newPosts = await Promise.all(newPostsPromises);

      let newIsLastPost = { ...isLastPost };
      if (newPosts.length < 15) {
        newIsLastPost[type] = true;

        if (type === 0) {
          newIsLastPost[1] = true;
          newIsLastPost[2] = true;
        }
      }

      // post를 불러온 post id들 true로 바꾸기
      let newPostIdsObj = { ...postIds };
      newPostIds.forEach(item => {
        newPostIdsObj[item] = true;
      })
      
      return { newPostIdsObj, newPosts, newIsLastPost };
    } catch (e) {
      console.error("Error loading posts: ", e);
      throw e;
    }
  }

  // Answer
  async createAnswer(postData, newAnswer) {
    const docRef = doc(this.resultCollectionRef, postData.answerIds[postData.answerIds.length - 1]);

    try {
      // 문서의 현재 상태를 조회합니다.
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        // 현재 'answers' 배열에서 같은 user uid가 포함되어 있는 항목을 찾습니다.
        const currentAnswers = docSnap.data().answers || [];
        const parsedAnswers = currentAnswers.map(answer => JSON.parse(answer));
        const itemsToRemove = parsedAnswers.filter(answer => answer.userUid === newAnswer.userUid);
        const itemsToRemoveStrings = itemsToRemove.map(answer => JSON.stringify(answer));

        // 조건에 맞는 항목을 제거하고 새로운 답변을 추가합니다.
        if (itemsToRemoveStrings.length !== 0) {
          await updateDoc(docRef, {
            // 조건에 맞는 항목을 제거합니다.
            answers: arrayRemove(...itemsToRemoveStrings),
          });
        }

        if (newAnswer.answer[0] !== null) {
          await updateDoc(docRef, {
            // 새로운 답변을 추가합니다.
            answers: arrayUnion(JSON.stringify(newAnswer))
          });
        }

        // 만약 answers의 데이터가 4,000개 이상이라면 answers > 새로운 answer 생성과 post > answers 새로운 answerId 추가
        if (currentAnswers.length >= 4000) {
          const currentAnswerNum = postData.answerIds.length;
          const newAnswerId = `${postData.id}-${currentAnswerNum + 1}`;

          // 'results' 컬렉션에 새 문서를 생성
          const resultsDocRef = doc(this.resultCollectionRef, newAnswerId);
          const resultDocSnap = await getDoc(resultsDocRef);

          if (!resultDocSnap.exists()) {
            await setDoc(resultsDocRef, { id: newAnswerId, answers: [] });

            // 'post'에 answers update
            const postDocRef = doc(this.postCollectionRef, postData.id);
            await updateDoc(postDocRef, {
              answerIds: arrayRemove(newAnswerId)
            });
            await updateDoc(postDocRef, {
              answerIds: arrayUnion(newAnswerId)
            });
          }
        }
      }
    } catch (error) {
      console.error("Error updating answer: ", error);
    }
  };

  async readAllAnswers(post) {
    try {
      // Promise.all을 사용하여 모든 비동기 작업이 완료될 때까지 기다립니다.
      const answersPromises = post.answerIds.map(async (answerId) => {
        const docRef = doc(this.resultCollectionRef, answerId);
        const docSnap = await getDoc(docRef);
        return docSnap.data().answers; // 각 answerId에 대한 answers 반환
      });

      // answersPromises 배열에 담긴 모든 프로미스가 이행된 후, 결과를 하나의 배열로 합칩니다.
      const answersArrays = await Promise.all(answersPromises);
      const answers = answersArrays.flat(); // 모든 answers 배열을 하나로 합칩니다.
      return answers;
    } catch (e) {
      console.error(e);
      return []; // 에러가 발생한 경우, 빈 배열 반환
    }
  }

  async deleteAnswer(postId, answerToRemove) {
    const docRef = doc(this.resultCollectionRef, postId);
    try {
      await updateDoc(docRef, {
        answers: arrayRemove(answerToRemove),
      });
    } catch (error) {
      console.error("Error removing answer: ", error);
    }
  };

  async resizeImage(url, maxWidth = 500, maxHeight = 375, quality = 0.5) {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height = height * (maxWidth / width);
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = width * (maxHeight / height);
            height = maxHeight;
          }
        }

        const canvas = document.createElement('canvas');
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);
        canvas.toBlob((blob) => {
          resolve(blob);
        }, 'image/jpeg', quality);
      };
      img.onerror = error => reject(error);
    });
  }

  async uploadImageAndGetURL(imageUrl) {
    try {
      // 이미지 크기 조절
      const resizedImageBlob = await this.resizeImage(imageUrl);

      // Firebase Storage의 인스턴스와 참조 생성
      const storageRef = ref(storage, `post/images/${new Date().toISOString()}.jpg`);

      // 조절된 이미지를 Storage에 업로드하고, 업로드가 성공하면 downloadURL을 반환
      const uploadTask = uploadBytesResumable(storageRef, resizedImageBlob);

      return new Promise((resolve, reject) => {
        uploadTask.on('state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          (error) => {
            console.error('Upload failed', error);
            reject(null);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              resolve(downloadURL);
            });
          }
        );
      });
    } catch (error) {
      console.error('Failed to resize or upload image:', error);
    }
  }
}

export default Response;
