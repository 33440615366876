import React, { useState, useEffect } from "react";
import styles from "./SurveyTotalView.module.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { useAnswers } from "../../contexts/AnswerContext";
import EssayView from "../../component/data_analytics/EssayView";
import MultipleChoiceView from "../../component/data_analytics/MultipleChoiceView";
import { useNavigation } from "../../contexts/NavigationContext";

const SurveyTotalView = () => {
    const { isInternalNavigation } = useNavigation();

    // Provider
    const { getAnswers } = useAnswers();
    // Router
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state ? location.state : null;
    // useState
    const [post, setPost] = useState({ options: [] });
    const [questions, setQuestions] = useState([]);
    const [answers, setAnswers] = useState([]);
    const [answerObjs, setAnswerObjs] = useState([]);

    // useEffect
    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            const fetchData = async () => {
                let isAllDataLoaded = data && data.post;
                if (!isAllDataLoaded) return;
                const post = data.post;

                const newAnswers = await getAnswers(post);
                const newAnswerObjs = [];
                newAnswers.forEach((newAnswer) => {
                    newAnswerObjs.push(JSON.parse(newAnswer));
                })
                const newQuestions = [];
                post.questionIds.forEach((questionId) => {
                    if (questionId.startsWith('essay')) {
                        newQuestions.push(post.essays.find(essay => essay.id === questionId));
                    } else {
                        newQuestions.push(post.multipleChoices.find(multipleChoice => multipleChoice.id === questionId));
                    }
                })
                setPost(post);
                setQuestions(newQuestions);
                setAnswers(newAnswers);
                setAnswerObjs(newAnswerObjs);
            };

            fetchData();
        }
    }, [navigate, isInternalNavigation, data, getAnswers]);

    const handleToIndividualView = () => {
        navigate('/survey-individual-view', {
            state: {
                survey: post,
                answers: answers
            }
        })
    }

    const handleToEssayTotalView = (currentNum, essay, answerObjs) => {
        navigate('/essay-total-view', {
            state: {
                currentNum: currentNum,
                essay: essay,
                answerObjs: answerObjs
            }
        })
    }

    const handleToAnalyticsMain = (currentNum, post, answers) => {
        navigate('/analytics-main', {
            state: {
                currentNum: currentNum,
                post: post,
                answers: answers
            }
        })
    }

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>데이터 분석</p>
            <div className="blank-30"/>
            <div className={styles.thumbnail_container}>
                <p className={styles.thumbnail_type}>설문조사</p>
                <div className='blank-10' />
                <p className={styles.thumbnail_title}>{post.title}</p>
                <div className='blank-20' />
                <img className={styles.thumbnail_image} src={post.thumbnailImage} alt="설문조사 썸네일" />
                <div className='blank-20' />
                <p className={styles.thumbnail_total_num}>{`${post.totalCount}명 참여`}</p>
            </div>
            <button className={styles.to_survey_individual_view} type="button" onClick={handleToIndividualView}>개별 보기</button>
            <div className={styles.question_section}>
                {questions.map((question, i) => {
                    const newAnswers = [];
                    answerObjs.forEach((answerObj) => {
                        newAnswers.push(answerObj.answer[question.id]);
                    });
                    if (question.type === "essay") {
                        return (<div key={question.id} type="button" onClick={() => { handleToEssayTotalView(i + 1, question, answerObjs) }}>
                            <EssayView currentNum={i + 1} essay={question} essayAnswers={newAnswers} />
                        </div>)
                    } else {
                        return (<div key={question.id} type="button" onClick={() => { handleToAnalyticsMain(i + 1, question, answers) }}>
                            <MultipleChoiceView key={question.id} currentNum={i + 1} multipleChoice={question} multipleChoiceAnswers={newAnswers} />
                        </div>)
                    }
                })}
            </div>
        </div>
    )
}

export default SurveyTotalView;