// ✅ GPT 코드리뷰
export class User {
    /**
     * User 클래스 생성자.
     * @param {string} uid 사용자 ID.
     * @param {string} profile 사용자 프로필.
     * @param {string} nickname 사용자 닉네임.
     * @param {string} email 사용자 이메일.
     * @param {string} birthday 사용자 생일.
     * @param {number} sex 사용자 성별.
     * @param {string} address 사용자 주소.
     * @param {string[]} participatedPostInfos 참여한 게시글 ID 목록. '{post_id}/{choiceIndex}'
     * @param {boolean} isServiceTermsAgreed 서비스 이용약관 동의 여부.
     * @param {boolean} isPrivacyPolicyAgreed 개인정보 처리방침 동의 여부.
     * @param {boolean} isAdmin 관리자 여부.
     */

    constructor({
        uid,
        profile,
        nickname,
        email,
        birthday,
        sex,
        address,
        participatedPostInfos,
        isServiceTermsAgreed,
        isPrivacyPolicyAgreed,
        isAdmin
    }) {
        this.uid = uid;
        this.profile = profile;
        this.nickname = nickname;
        this.email = email;
        this.birthday = birthday;
        this.sex = sex;
        this.address = address;
        this.participatedPostInfos = participatedPostInfos;
        this.isServiceTermsAgreed = isServiceTermsAgreed;
        this.isPrivacyPolicyAgreed = isPrivacyPolicyAgreed;
        this.isAdmin = isAdmin;
    }

    // 사용자의 나이를 계산하는 메소드
    getAge() {
        const today = new Date();
        const birthDate = new Date(this.birthday);
        let age = today.getFullYear() - birthDate.getFullYear();
        const m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
        return age;
    }

    // 사용자가 게시물에 참여했는지 확인하는 메소드
    hasParticipated(postId) {
        return this.participatedPostInfos.includes(postId);
    }

    // 서비스 약관 동의를 업데이트하는 메소드
    agreeToServiceTerms() {
        this.isServiceTermsAgreed = true;
    }

    // 개인정보 처리방침 동의를 업데이트하는 메소드
    agreeToPrivacyPolicy() {
        this.isPrivacyPolicyAgreed = true;
    }

    // 사용자가 관리자인지 확인하는 메소드
    checkIfAdmin() {
        return this.isAdmin;
    }

    static fromJson(jsonData) {
        return new User({
            uid: jsonData.uid,
            profile: jsonData.profile,
            nickname: jsonData.nickname,
            email: jsonData.email,
            birthday: jsonData.birthday,
            sex: jsonData.sex,
            address: jsonData.address,
            participatedPostInfos: jsonData.participatedPostInfos,
            isServiceTermsAgreed: jsonData.isServiceTermsAgreed,
            isPrivacyPolicyAgreed: jsonData.isPrivacyPolicyAgreed,
            isAdmin: jsonData.isAdmin
        });
    }

    toJson() {
        return {
            'uid':this.uid,
            'profile': this.profile,
            'nickname':this.nickname,
            'email':this.email,
            'birthday':this.birthday,
            'sex':this.sex,
            'address':this.address,
            'participatedPostInfos':this.participatedPostInfos,
            'isServiceTermsAgreed':this.isServiceTermsAgreed,
            'isPrivacyPolicyAgreed':this.isPrivacyPolicyAgreed,
            'isAdmin':this.isAdmin
        };
    }
}