import React from "react";
import styles from "./RowBarChart.module.css";

const RowBarChart = ({ data }) => {
    let total = 0;
    Object.values(data).forEach(value => {
        total += value;
    });

    const calculatePercentage = (part) => total === 0 ? 0 : Math.floor((part / total) * 100);

    // 1단계: 최대 값 찾기
    const maxValue = Math.max(...Object.values(data), 0);

    return (
        <div className={styles.component}>
            {Object.keys(data).map((key) => {
                // 2단계: 각 바의 상대적 높이 계산
                const barHeight = maxValue === 0 ? 0 : (data[key] / maxValue) * 150; // 최대 150px
                // 3단계: 최대 값을 가진 바에 대한 스타일 적용
                const barColor = data[key] !== maxValue || maxValue === 0 ? 'var(--sub-10-color)' : 'var(--main-color)'; // 최대 값이면 검은색, 아니면 기본 색상
                const textColor = data[key] !== maxValue || maxValue === 0 ? 'var(--sub-font-color)' : 'var(--main-font-color)';
                const textWeight = data[key] !== maxValue || maxValue === 0 ? 'normal' : 'bold';
                return (
                    <div key={key} className={styles.bar_container}>
                        <p className={styles.bar_percentage} style={{ fontWeight: textWeight, color: textColor }}>
                            {`${calculatePercentage(data[key])}%`}
                        </p>
                        <div className={styles.bar} style={{ height: `${barHeight + 10}px`, backgroundColor: barColor }} />
                        <p className={styles.bar_label} style={{ fontWeight: textWeight, color: textColor }}>{key}</p>
                    </div>
                );
            })}
        </div>
    );
}

export default RowBarChart;