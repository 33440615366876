import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from "./Analytics.module.css";
import { calculateAgeDistribution } from '../../model/util/ageUtils';
import { calculateSexDistribution } from '../../model/util/sexUtil';
import { calculateAddressDistribution } from '../../model/util/addressUtil';
import RowBarChart from '../../component/RowBarChart';
import ColumnBarChart from '../../component/ColumnBarChart';
import { useNavigation } from '../../contexts/NavigationContext';

const Analytics = () => {
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    // useState
    const [ageDistribution, setAgeDistribution] = useState({});
    const [sexDistribution, setSexDistribution] = useState({});
    const [addressDistribution, setAddressDistribution] = useState({});
    const [totalNum, setTotalNum] = useState(0);
    const [participateNum, setParticipateNum] = useState(0);
    const [post, setPost] = useState({});
    const [option, setOption] = useState({});

    // Router
    const location = useLocation();
    const data = location.state ? location.state : null;

    // useEffect
    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            // 모든 데이터 있는지 체크
            let isAllDataLoaded = data && [data.post, data.currentIndex, data.answers].every(item => item !== null);

            if (!isAllDataLoaded) return;

            // 데이터 할당
            const post = data.post;
            const currentIndex = data.currentIndex;
            const answers = data.answers;

            // currentIndex에 해당하는 answer<string>로 구성된 answersFiltered
            const answerObjsFiltered = answers.map((item) => JSON.parse(item)).filter((itemObj) => {
                let myAnswer = post.type === 'vote' ? itemObj['answer'][0] : itemObj['answer'][post.id];
                return myAnswer === currentIndex;
            });

            // 차트 표시를 위한 데이터 정리
            const birthdateList = answerObjsFiltered.map(item => item['userBirthday']);
            const sexList = answerObjsFiltered.map(item => item['userSex']);
            const addressList = answerObjsFiltered.map(item => item['userAddress']);

            // 데이터 정리 및 상태 업데이트
            setAgeDistribution(calculateAgeDistribution(birthdateList));
            setSexDistribution(calculateSexDistribution(sexList));
            setAddressDistribution(calculateAddressDistribution(addressList));

            // 카운팅
            setTotalNum(answers.length);
            setParticipateNum(answerObjsFiltered.length);

            // post 업데이트
            setPost(post);

            // option 업데이트
            setOption(post['options'][currentIndex]);
        }
    }, [navigate, isInternalNavigation, data]);

    const calculatePercentage = (participateNum, totalCount) => {
        if (typeof participateNum !== 'number' || typeof totalCount !== 'number' || totalCount === 0) {
            return 0; // NaN을 반환하지 않고, 대신 0%를 반환
        }
        return Math.floor((participateNum / totalCount) * 100);
    };

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>참여자 통계</p>
            <div className="blank-30" />
            <div className={styles.post_container}>
                <p className={styles.post_type}>투표</p>
                <div className='blank-10' />
                <p className={styles.post_title}>{post.title}</p>
                <div className='blank-20' />
                <div key={option.id} className={styles.post_option_container}>
                    {option.image !== '' ? <img src={option.image} alt={option.content} className={styles.post_option_image} /> :
                        <div className={styles.post_option_image_blank} />}
                    <div className={styles.post_option_details}>
                        <div className={styles.post_option_contentRow}>
                            <p className={styles.post_option_text}>{option.post_option_content}</p>
                            <div style={{ flex: 1 }}></div>
                            <p className={styles.post_option_text}>
                                {`${calculatePercentage(participateNum, totalNum)}% (${participateNum})`}
                            </p>
                        </div>
                        <div>
                            <div className='blank-10' />
                            <div className={styles.post_option_progressBar_container}>
                                <div className={styles.post_option_progress_bar} style={{
                                    width: `${calculatePercentage(participateNum, totalNum)}%`,
                                    opacity: `${participateNum >= totalNum / 2 ? 1 : 0.2}`
                                }}></div>
                            </div>
                        </div>
                    </div>
                </div>
                <p className={styles.total_num}>{`${totalNum}명 투표`}</p>
            </div>
            <div className={styles.data_container}>
                <div className={styles.participate_info}>
                    <div className='blank-50' />
                    <p className={styles.participate_info_text}>{`${totalNum}명 중`} <span style={{ fontWeight: 'bold' }}>{`${participateNum}명 투표`}</span></p>
                    <div className='blank-50' />
                </div>
                <div className={styles.graph_section}>
                    <div className={styles.graph_container}>
                        <p className={styles.graph_title}>
                            연령대
                        </p>
                        <RowBarChart data={ageDistribution} />
                    </div>
                    <div className='blank-50' />
                    <div className={styles.graph_container}>
                        <p className={styles.graph_title}>
                            성별
                        </p>
                        <RowBarChart data={sexDistribution} />
                    </div>
                    <div className='blank-50' />
                    <div className={styles.graph_container}>
                        <p className={styles.graph_title}>
                            거주 지역
                        </p>
                        <ColumnBarChart data={addressDistribution} />
                    </div>
                </div>
            </div>
            <div className='blank-100' />
        </div>
    )
}

export default Analytics;
