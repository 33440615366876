// ✅ GPT 코드리뷰
import React, { useState, useEffect } from 'react';
import styles from './VotePost.module.css';
import CustomCheckBox from './CustomCheckBox';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { usePosts } from '../contexts/PostContext';
import { TiDelete } from "react-icons/ti";
import DeleteConfirmModal from './modal/DeleteConfirmModal';
import { IoIosArrowForward } from "react-icons/io";

const REACTIVATION_DELAY = 5000;

const calculateOpacity = (votes, maxVotes) => votes / maxVotes;

const VotePost = ({ voteData }) => {
    const navigate = useNavigate();
    const { userData } = useUser();
    const { updateVotePostById, deletePostIdById } = usePosts();
    const { title } = voteData;

    // useState
    const [checkBoxActive, setCheckBoxActive] = useState(true);
    const [totalNum, setTotalNum] = useState(voteData.totalCount);
    const [optionNums, setOptionNums] = useState([
        voteData.firstOptionCount,
        voteData.secondOptionCount,
        voteData.thirdOptionCount,
        voteData.fourthOptionCount,
        voteData.fifthOptionCount,
    ]);
    const [currentChoiceIndex, setCurrentChoiceIndex] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        const findUserChoiceIndex = () => {
            if (userData && userData.participatedPostInfos && voteData.id) {
                const postInfoString = userData.participatedPostInfos.find(info => info.startsWith(`${voteData.id}/`));
                if (postInfoString) {
                    const [, choiceIndex] = postInfoString.split('/');
                    return parseInt(choiceIndex, 10);
                }
            }
            return null;
        };
        setCurrentChoiceIndex(findUserChoiceIndex());
    }, [userData, voteData.id]);

    const handleCheckBoxClick = (index) => {
        if (!checkBoxActive) {
            alert('잠시 후, 다시 시도해주세요');
            return;
        }
        setCheckBoxActive(false);

        // Update
        updateVotePostById(voteData.id, currentChoiceIndex, index);

        // VotePost 상태 변경
        setTotalNum((prevTotal) => {
            let newTotal = prevTotal;
            if (currentChoiceIndex === null) newTotal++;
            else if (currentChoiceIndex !== null && currentChoiceIndex !== index) newTotal = prevTotal;
            else newTotal--;
            return newTotal;
        });

        setOptionNums((prevOptions) => {
            const newOptions = [...prevOptions];
            if (currentChoiceIndex === null) newOptions[index]++;
            else {
                newOptions[currentChoiceIndex]--;
                if (currentChoiceIndex !== index) newOptions[index]++;
            }
            return newOptions;
        });

        setCurrentChoiceIndex((prevIndex) => (prevIndex === index ? null : index));

        setTimeout(() => setCheckBoxActive(true), REACTIVATION_DELAY);
    };

    const handleDeleteBtnClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        await deletePostIdById(voteData.id);
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleToAnalyticsPage = () => {
        navigate('/analytics-main', {
            state: {
                post: voteData
            }
        })
    }

    return (
        <div className={styles.page}>
            <div className={styles.container}>
                {/* 게시글 삭제 버튼(관리자 전용) */}
                {userData && userData.isAdmin ?
                    <div type="button" className={styles.delete_btn} onClick={handleDeleteBtnClick}>
                        <TiDelete size={32} color="rgba(10, 10, 10, 0.3)" />
                    </div> : null
                }
                <DeleteConfirmModal post={voteData} isOpen={isModalOpen} onClose={handleCloseModal} onDelete={handleConfirmDelete} />
                {/* 게시글 분석 버튼(관리자 전용) */}
                {userData && userData.isAdmin ?
                    <div type="button" className={styles.to_analytics_page_btn} onClick={handleToAnalyticsPage}>
                        <p className={styles.to_analytics_page_btn_text}>분석 보러가기</p>
                        <div className='blank-5' />
                        <IoIosArrowForward size={18} color="var(--main-font-color)" />
                    </div> : null
                }
                <p className={styles.type}>투표</p>
                <div className='blank-10' />
                <p className={styles.title}>{title}</p>
                <div className='blank-20' />
                {voteData.options.map((option, i) => (
                    <div key={i} className={styles.optionContainer}>
                        <CustomCheckBox checked={currentChoiceIndex === i} onChange={() => {
                            if (!userData) {
                                alert('로그인 후, 이용할 수 있습니다');
                                try {
                                    navigate('/login');
                                } catch (e) {
                                    console.log(e);
                                }
                            } else handleCheckBoxClick(i);
                        }} />
                        <div className='blank-10' />
                        {option.image !== '' ? <img src={option.image} alt={option.content} className={styles.image} /> :
                            <div className={styles.image_blank} />}
                        <div className={styles.optionDetails}>
                            <div className={styles.contentRow}>
                                <p className={styles.option_text}>{option.content}</p>
                                <div style={{ flex: 1 }}></div>
                                {currentChoiceIndex !== null && (
                                    <p className={styles.option_text}>
                                        {`${(100 * optionNums[i] / totalNum).toFixed(0)}% (${optionNums[i]})`}
                                    </p>
                                )}
                            </div>
                            {currentChoiceIndex !== null && (
                                <div>
                                    <div className='blank-10' />
                                    <div className={styles.progressBarContainer}>
                                        <div className={styles.progress_bar} style={{
                                            width: `${(100 * optionNums[i] / totalNum)}%`,
                                            opacity: calculateOpacity(optionNums[i], Math.max(...optionNums))
                                        }}></div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
                <p className={styles.total_num}>{`${totalNum}명 투표`}</p>
            </div>
        </div>
    );
};

export default VotePost;
