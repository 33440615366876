import React, { useEffect, useState } from 'react';
import styles from './SurveyMc.module.css';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import ImageUploadButton from '../../component/ImageUploadButton'
import { TiDelete } from "react-icons/ti";
import { v4 as uuidv4 } from 'uuid';

const SurveyMc = ({ currentTitle, currentOptions, currentNum,
    isPrevExist, isNextExist, notifyParent,
    onClickPrevBtn, onClikNextBtn, onClickDeleteBtn }) => {
    const [title, setTitle] = useState('');
    const [options, setOptions] = useState([]);

    useEffect(() => {
        setTitle(currentTitle);
        setOptions(currentOptions);
    }, [currentTitle, currentOptions])

    // uploadImage
    const uploadImage = (id, imageUrl) => {
        const newOptions = options.map((option) => {
            if (option.id === id) {
                return { ...option, image: imageUrl };
            }
            return option;
        });
        setOptions(newOptions);
        notifyParent('options', newOptions);
    }
    

    const handleTitleChange = (value) => {
        notifyParent('title', value)
        setTitle(value);
    }

    const handleOptionsChange = (i, value) => {
        let newOptions = [...options];
        newOptions[i].content = value;
        notifyParent('options', newOptions);
    }

    const handleDeleteOption = (i) => {
        if (options.length > 2) {
            let newOptions = [...options];
            newOptions = newOptions.filter((item, index) => index !== i);
            notifyParent('options', newOptions);
        }
    }

    const handleAddOption = () => {
        if (options.length < 5) {
            let newOptions = [...options];
            newOptions.push({ content: '', image: '', id: uuidv4() });
            notifyParent('options', newOptions);
        }
    }

    return (
        <div className={styles.container}>
            <div className={styles.number_container}>
                {!isPrevExist || <div className={styles.prev_btn} onClick={onClickPrevBtn}>
                    <IoIosArrowBack color='rgba(10, 10, 10, 1)' size={24} />
                </div>}
                {!isPrevExist || <div className='blank-10' />}
                <p className={styles.number}>
                    {`Q${currentNum}`}
                </p>
                {!isNextExist || <div className='blank-10' />}
                {!isNextExist || <div className={styles.next_btn} onClick={onClikNextBtn}>
                    <IoIosArrowForward color='rgba(10, 10, 10, 1)' size={24} />
                </div>}
                <div className={styles.blank_fill} />
                <button className={styles.question_delete_btn} onClick={onClickDeleteBtn}>
                    <TiDelete size={30} color="rgba(10, 10, 10, 1)" />
                </button>
            </div>
            <div className='blank-20' />
            <input className={styles.title_input}
                value={title}
                onChange={(e) => { handleTitleChange(e.target.value) }}
                placeholder='제목을 작성해주세요' />
            <div className='blank-20' />
            {options.map((option, i) => {
                return (
                    <div className={styles.option_container} key={option.id}>
                        <ImageUploadButton currentImageUrl={option.image} index={option.id} notifyParent={uploadImage} />
                        <div className="blank-20" />
                        <input className={styles.content_input}
                            type="text"
                            placeholder={`선택지 ${i + 1}`}
                            value={option.content}
                            onChange={(e) => handleOptionsChange(i, e.target.value)} />
                        <button className={styles.delete_btn} onClick={() => handleDeleteOption(i)}>
                            <TiDelete size={30} color="rgba(10, 10, 10, 0.3)" />
                        </button>
                    </div>
                );
            })}
            <div className="blank-10" />
            <button className={styles.add_btn} type="button" onClick={handleAddOption}>선택지 추가하기</button>
        </div>
    )
}

export default SurveyMc;