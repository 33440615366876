import React from 'react';
import styles from './CircleLoader.module.css'; // CSS 모듈을 불러옵니다.

const CircleLoader = ({ size = 18, width = 2, color = '#ffffff' }) => {
  const loaderStyle = {
    width: `${size}px`,
    height: `${size}px`,
    border: `${width}px solid ${color}`,
    borderTop: `${width}px solid transparent`,
  };

  return (
    <div className={styles.loader} style={loaderStyle}></div>
  );
};

export default CircleLoader;