// ✅ GPT 코드리뷰
import React, { useState } from 'react';
import styles from './CustomDropdown.module.css';

const CustomDropdown = ({ id, isOpen, options, toggleDropdown, onSelect, placeholder}) => {
  const [selectedLabel, setSelectedLabel] = useState(placeholder); // 초기 레이블 설정

  const handleSelect = (option) => {
    setSelectedLabel(option); // 선택된 옵션의 레이블로 업데이트
    onSelect(option, id); // 상위 컴포넌트로 선택된 옵션 전달
  };

  return (
    <div className={styles.dropdown_container}>
      <button type='button' onClick={() => toggleDropdown(id)} className={selectedLabel!==placeholder?styles.dropdown_btn:[`${styles.dropdown_btn} ${styles.unselected}`]}>
        {selectedLabel} {/* 선택된 값 또는 기본 텍스트를 버튼에 표시 */}
      </button>
      {isOpen === id && (
        <ul className={styles.dropdown_menu}>
          {options.map(option => (
            <li key={option} className={styles.dropdown_item} onClick={() => handleSelect(option)}>
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CustomDropdown;
