// ✅ GPT 코드리뷰
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './Welcome.module.css';
import { useNavigation } from '../../contexts/NavigationContext';

const Welcome = () => {
    const { isInternalNavigation } = useNavigation();

    // Router
    const location = useLocation();
    const data = location.state.message ? location.state.message : null;
    const isAllMessageDataLoaded = [data.main_message, data.description].every(item => item !== null);
    const userData = location.state.userData ? location.state.userData : null;
    const isAllUserDataLoaded = [userData.uid, userData.nickname, userData.email,
    userData.birthday, userData.sex, userData.address, userData.participatedPostIds,
    userData.isServiceTermsAgreed, userData.isPrivacyPolicyAgreed, userData.isAdmin].every(item => item !== null);

    const navigate = useNavigate();

    // UseEffect
    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            if (data == null || !isAllMessageDataLoaded || userData == null || !isAllUserDataLoaded) {
                try {
                    navigate('/login', { replace: true });
                } catch (e) {
                    console.log(e);
                }
            }
        }
    }, [navigate, isInternalNavigation, data, isAllMessageDataLoaded, userData, isAllUserDataLoaded]);

    // next_btn Click
    const handleClick = () => {
        try {
            navigate('/', {
                replace: true,
                state: userData
            });
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <div className={styles.page}>
            <div className='blank-100' />
            <p className={styles.description}>{data.description}</p>
            <div className='blank-20' />
            <div className={styles.main_message}>{data.main_message}</div>
            <div className='blank-100' />
            <button className={styles.next_btn} onClick={handleClick}>확인</button>
        </div>
    );
}

export default Welcome;
