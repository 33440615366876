import React, { useEffect, useState } from "react";
import styles from "./EssayTotalView.module.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { calculateAgeGroup } from '../../model/util/calculateAgeGroup';
import { useNavigation } from "../../contexts/NavigationContext";

const EssayTotalView = () => {
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    // Router
    const location = useLocation();
    const data = location.state ? location.state : null;

    // useState
    const [currentNum, setCurrentNum] = useState(0);
    const [essay, setEssay] = useState({});
    const [answerObjs, setAnswerObjs] = useState([]);

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            let isAllDataLoaded = data && data.currentNum && data.essay && data.answerObjs;
            if (!isAllDataLoaded) return;

            setCurrentNum(data.currentNum);
            setEssay(data.essay);
            setAnswerObjs(data.answerObjs);
        }
    }, [navigate, isInternalNavigation, data]);

    return (<div className={styles.page}>
        <p className={styles.page_name}>답변 모두 보기</p>
        <div className="blank-30" />
        <div className={styles.container}>

            <div className={styles.number_container}>
                <p className={styles.number}>
                    {`Q${currentNum}`}
                </p>
            </div>
            <div className='blank-20' />
            <p className={styles.title}>
                {essay.title}
            </p>
            <div className='blank-50' />
            <div className={styles.answer_section}>
                {(answerObjs).map((answerObj, i) => {
                    return (
                        <div key={`answer-${i}`} className={styles.answer_container} style={{ marginBottom: i === answerObjs.length - 1 ? 0 : 10 }}>
                            <div className={styles.answer_text_container}>
                                <p className={styles.answer_text}>
                                    {answerObj.answer[essay.id]}
                                </p>
                            </div>
                            <p className={styles.answer_info_text}>
                                {`${answerObj.userNickname} / ${calculateAgeGroup(answerObj.userBirthday)} / ${answerObj.userSex === 0 ? '남' : '여'} / ${answerObj.userAddress}`}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    </div>

    )
}

export default EssayTotalView;