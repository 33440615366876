import React, { useEffect, useState } from 'react';
import { BsPlusCircle } from "react-icons/bs";

function ImageUploadButton({ currentImageUrl, index, notifyParent }) {
  const [imageUrl, setImageUrl] = useState('');

  useEffect(() => {
    setImageUrl(currentImageUrl);
  }, [currentImageUrl]);

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      const newImageUrl = URL.createObjectURL(file);

      setImageUrl(newImageUrl);
      notifyParent(index, newImageUrl);
      // URL.revokeObjectURL(newImageUrl); Consider revoking the created URL after it's no longer needed
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <label htmlFor={`upload-button-${index}`} style={{
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        padding: '10px',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        {imageUrl ? (
          <img src={imageUrl} alt="Uploaded" style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }} />
        ) : (
          <BsPlusCircle color='rgba(10, 10, 10, 0.3)' size={50} />
        )}
      </label>
      <input 
        id={`upload-button-${index}`} 
        type="file" 
        accept="image/*" 
        onChange={handleImageChange} 
        style={{ display: 'none' }} />
    </div>
  );
}

export default ImageUploadButton;
