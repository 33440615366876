import React, { useState, useEffect } from "react";
import styles from './CreateVote.module.css';
import { useUser } from '../../contexts/UserContext';
import { usePosts } from '../../contexts/PostContext';
import ImageUploadButton from '../../component/ImageUploadButton';
import { TiDelete } from "react-icons/ti";
import { Response } from '../../model/response/response';
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from 'uuid'; // UUID 라이브러리를 사용하여 고유한 ID를 생성합니다.
import { useNavigation } from "../../contexts/NavigationContext";

const CreateVote = () => {
    const { isInternalNavigation } = useNavigation();
    const response = new Response();
    const { userData } = useUser();
    const { createPost } = usePosts();
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([
        { content: '', image: '', id: uuidv4() },
        { content: '', image: '', id: uuidv4() }
    ]);

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        }
    }, [navigate, isInternalNavigation])

    const uploadImage = (i, imageUrl) => {
        let newOptions = [...options];
        newOptions[i].image = imageUrl;
        setOptions(newOptions);
    };

    const handleContentChange = (i, value) => {
        let newOptions = [...options];
        newOptions[i].content = value;
        setOptions(newOptions);
    };

    const deleteChoice = (i) => {
        if (options.length > 2) {
            let newOptions = [...options];
            newOptions = newOptions.filter((_, index) => index !== i);
            setOptions(newOptions);
        }
    };

    const addChoice = () => {
        if (options.length < 5) {
            let newOptions = [...options];
            newOptions.push({ content: '', image: '', id: uuidv4() });
            setOptions(newOptions);
        }
    };

    const handleSubmit = async () => {
        if (isLoading) return;
        setIsLoading(true);

        if (title === '' || options.some((item) => item.content === '')) {
            alert('내용을 모두 채워주세요');
            setIsLoading(false);
            return;
        }

        try {
            const uploadImagePromises = options.map(async (item) => {
                if (item.image !== '') {
                    const newImageUrl = await response.uploadImageAndGetURL(item.image);
                    return { ...item, image: newImageUrl, id: item.id };
                }
                return item;
            });

            const newOptions = await Promise.all(uploadImagePromises);

            const dateString = new Date().toISOString()
            const newVote = {
                id: `vote-${dateString}`,
                date: dateString,
                type: 'vote',
                ownerUid: userData.uid,
                title: title,
                options: newOptions,
                totalCount: 0,
                firstOptionCount: 0,
                secondOptionCount: 0,
                thirdOptionCount: 0,
                fourthOptionCount: 0,
                fifthOptionCount: 0,
                answerIds: [`vote-${dateString}-0`]
                // postIdIdsNum은 response에서 추가
            };

            await createPost(newVote);
            navigate('/create-complete', { replace: true });
        } catch (error) {
            console.error("Error during the vote creation:", error);
            alert('투표 생성 중 오류가 발생했습니다.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>투표 만들기</p>
            <div className="blank-30" />
            <div className={styles.container}>
                <p className={styles.type}>투표</p>
                <div className="blank-10" />
                <input
                    className={styles.title_input}
                    type="text"
                    placeholder="제목을 작성해주세요"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <div className="blank-20" />
                {options.map((option, i) => (
                    <div className={styles.option_container} key={option.id}>
                        <ImageUploadButton
                            currentImageUrl={option.image}
                            index={i}
                            notifyParent={uploadImage}
                        />
                        <div className="blank-20" />
                        <input
                            className={styles.content_input}
                            type="text"
                            placeholder={`선택지 ${i + 1}`}
                            value={option.content}
                            onChange={(e) => handleContentChange(i, e.target.value)}
                        />
                        <button
                            className={styles.delete_btn}
                            onClick={() => deleteChoice(i)}
                        >
                            <TiDelete size={30} color="rgba(10, 10, 10, 0.3)" />
                        </button>
                    </div>
                ))}
                <div className="blank-10" />
                <button
                    className={styles.choice_add_btn}
                    type="button"
                    onClick={addChoice}
                >
                    선택지 추가하기
                </button>
            </div>
            <div className="blank-50" />
            <button
                onClick={handleSubmit}
                className={styles.submit_btn}
                type="button"
            >
                <p className={styles.submit_btn_text} style={!isLoading ? {} : { display: 'none' }}>업로드하기</p>
                <div className={styles.loader} style={isLoading ? {} : { display: 'none' }}></div>
            </button>
            <div className="blank-50"/>
        </div>
    );
};

export default CreateVote;
