// ✅ GPT 코드리뷰
import React, { createContext, useState, useContext, useEffect } from 'react';
import { Response } from './../model/response/response'; // 가정: Response 클래스가 response.js에 정의됨
import { useUser } from './UserContext';

const PostContext = createContext();

export const PostProvider = ({ children }) => {
  const { userData, updateUserData } = useUser();
  const [postIds, setPostIds] = useState({});
  const [posts, setPosts] = useState([]);
  const [isLastPost, setIsLastPost] = useState({});

  // response를 상태로 관리
  const [response] = useState(() => new Response());

  useEffect(() => {
    if (posts.length === 0) {
      // 초기 게시물 불러오기 함수 정의
      const loadInitialPosts = async () => {
        const newPostIds = await response.readAllPostIds();
        const { newPostIdsObj, newPosts, newIsLastPost } = await response.loadPosts(newPostIds, [], { 0: false, 1: false, 2: false }, 0, false);
        setPostIds(newPostIdsObj);
        setPosts(newPosts);
        setIsLastPost(newIsLastPost);

        // 로컬 스토리지에 데이터 저장
        const currentTime = new Date().toISOString();
        localStorage.setItem('updateTime', currentTime);
        localStorage.setItem('postIds', JSON.stringify(newPostIdsObj));
        localStorage.setItem('posts', JSON.stringify(newPosts));
        localStorage.setItem('isLastPost', JSON.stringify(newIsLastPost));
      };

      // 마지막 업데이트 1시간 지났는지 검사
      const updateTime = localStorage.getItem('updateTime');
      const isDataPresent = updateTime && localStorage.getItem('postIds') && localStorage.getItem('posts') && localStorage.getItem('isLastPost');

      // Function to load data from local storage
      const loadDataFromLocalStorage = () => {
        setPostIds(JSON.parse(localStorage.getItem('postIds')));
        setPosts(JSON.parse(localStorage.getItem('posts')));
        setIsLastPost(JSON.parse(localStorage.getItem('isLastPost')));
      };

      if (isDataPresent) {
        const currentTime = new Date();
        const lastUpdateTime = new Date(updateTime);
        const hoursDifference = Math.abs(currentTime - lastUpdateTime) / 36e5;

        // If more than 1 hour has passed or user is an admin, reload initial posts
        if (hoursDifference >= 1 || (userData && userData.isAdmin)) {
          loadInitialPosts();
        } else {
          loadDataFromLocalStorage();
        }
      } else {
        loadInitialPosts();
      }
    }
  }, [response, userData, posts]);

  // 참여한 게시글 가져오기
  const getParticipatedPostIds = () => {
    const participatedPostIds = [];
    userData.participatedPostInfos.forEach(item => {
      participatedPostIds.push(item.split('/')[0]);
    })

    return participatedPostIds;
  }

  // 다음 페이지의 게시물 불러오기
  const loadMorePosts = async (type, isOnlyParticipatedPosts) => {
    if (isLastPost[type] === true) return; // 더 불러올 게시물이 없는 경우

    const { newPostIdsObj, newPosts, newIsLastPost } = await response.loadPosts(postIds, getParticipatedPostIds(), isLastPost, type, isOnlyParticipatedPosts);
    setPosts([...posts, ...newPosts]); // 새 게시물 추가
    setPostIds({ ...postIds, ...newPostIdsObj });
    setIsLastPost({ ...newIsLastPost });
    localStorage.setItem('postIds', JSON.stringify({ ...postIds, ...newPostIdsObj }));
    localStorage.setItem('posts', JSON.stringify([...posts, ...newPosts]));
    localStorage.setItem('isLastPost', JSON.stringify({ ...newIsLastPost }));
  };

  // 게시물 업데이트
  const updateVotePostById = async (postId, previousChoice, currentChoice) => {
    // DB - 증가 또는 감소해야 할 카운트들을 저장할 객체 초기화
    let incrementInfos = {};
    const answerData = {
      date: new Date().toISOString(),
      userUid: userData.uid,
      userProfile: userData.profile,
      userNickname: userData.nickname,
      userBirthday: userData.birthday,
      userSex: userData.sex,
      userAddress: userData.address,
      answer: [previousChoice !== currentChoice ? currentChoice : null]
    }

    // totalCount 업데이트 로직
    if (previousChoice === null && currentChoice !== null) {
      // 처음 투표하는 경우, totalCount 증가
      incrementInfos['totalCount'] = 1;
    } else if (previousChoice === currentChoice) {
      // 투표를 취소하는 경우, totalCount 감소
      incrementInfos['totalCount'] = -1;
    }

    // previousChoice와 currentChoice에 따른 옵션 카운트 업데이트 로직
    if (previousChoice !== null && currentChoice !== null) {
      if (previousChoice !== currentChoice) {
        // 이전 옵션 감소, 새 옵션 증가
        incrementInfos[optionKey(previousChoice)] = -1;
        incrementInfos[optionKey(currentChoice)] = 1;
      } else {
        // 같은 선택을 한 경우는 취소로 인식해 카운트 감소
        incrementInfos[optionKey(currentChoice)] = -1;
      }
    } else if (currentChoice !== null) {
      // 새로운 선택인 경우 해당 옵션 증가
      incrementInfos[optionKey(currentChoice)] = 1;
    }

    // Local Post 데이터 변경
    let newPosts = [...posts];
    const postIndex = newPosts.findIndex(post => post.id === postId);
    if (postIndex === -1) return; // post를 찾지 못한 경우 함수 종료
    Object.keys(incrementInfos).forEach(key => {
      newPosts[postIndex][key] += incrementInfos[key];
    });
    setPosts(newPosts);
    localStorage.setItem('posts', JSON.stringify(newPosts));

    // DB Post, Answer 데이터 변경
    await response.incrementPost(postId, incrementInfos);
    await response.createAnswer(posts.find((item) => item.id === postId), answerData);

    // Local, DB User 데이터 변경
    let newUserData = { ...userData };
    const newParticipatedPostInfos = newUserData.participatedPostInfos.filter(info => !info.startsWith(`${postId}/`));
    if (previousChoice !== currentChoice) {
      newParticipatedPostInfos.push(`${postId}/${currentChoice}`);
    }
    newUserData.participatedPostInfos = newParticipatedPostInfos;
    updateUserData(newUserData);
  };

  const updateSurveyPostById = async (postId, answers) => {
    const answerData = {
      date: new Date().toISOString(),
      userProfile: userData.profile,
      userUid: userData.uid,
      userNickname: userData.nickname,
      userBirthday: userData.birthday,
      userSex: userData.sex,
      userAddress: userData.address,
      answer: answers
    }

    // Local, DB Post totalCount ++
    let newPosts = [...posts];
    const postIndex = newPosts.findIndex(post => post.id === postId);
    if (postIndex === -1) return;
    newPosts[postIndex].totalCount++;
    setPosts(newPosts);
    localStorage.setItem('posts', JSON.stringify(newPosts));

    await response.incrementPost(postId, { 'totalCount': 1 });

    // Create answer
    await response.createAnswer(posts[postIndex], answerData);

    // Loca, DB User participantedPostInfos push(item)
    let newUserData = { ...userData };
    const newParticipatedPostInfos = newUserData.participatedPostInfos.filter(info => !info.startsWith(`${postId}/`));
    newParticipatedPostInfos.push(`${postId}/${JSON.stringify(answers)}`);
    newUserData.participatedPostInfos = newParticipatedPostInfos;
    updateUserData(newUserData);
  }

  // 게시물 생성
  const createPost = async (post) => {
    const newPost = await response.createPost(post);
    const newPosts = [newPost, ...posts];
    const newPostIds = { ...postIds };
    newPostIds[post.id] = true;
    setPosts(newPosts);
    setPostIds(newPostIds);
    localStorage.setItem('posts', JSON.stringify(newPosts));
    localStorage.setItem('postIds', JSON.stringify(newPostIds));
  }

  // 게시물 삭제
  const deletePostIdById = async (postId) => {
    const postToDelete = posts.find(item => item.id === postId);
    await response.deletePostId(postToDelete);
    const newPosts = posts.filter(item => item.id !== postId);
    const newPostIds = { ...postIds };
    delete newPostIds[postId];
    setPosts(newPosts);
    setPostIds(newPostIds);
    localStorage.setItem('posts', JSON.stringify(newPosts));
    localStorage.setItem('postIds', JSON.stringify(newPostIds));
  }

  // 선택한 옵션에 따라 해당하는 카운트 키를 반환하는 함수
  function optionKey(choiceIndex) {
    return ['first', 'second', 'third', 'fourth', 'fifth'][choiceIndex] + 'OptionCount';
  }

  return (
    <PostContext.Provider value={{ postIds, posts, isLastPost, loadMorePosts, updateVotePostById, updateSurveyPostById, createPost, deletePostIdById }}>
      {children}
    </PostContext.Provider>
  );
};

// 커스텀 훅
export const usePosts = () => useContext(PostContext);