// ✅ GPT 코드리뷰
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './SignUp.module.css';
import jsonData from '../../model/data/koreaRegions.json';
import CustomDropdown from '../../component/CustomDropdown';
import { useNavigation } from '../../contexts/NavigationContext';

const SignUp = () => {
    const { isInternalNavigation } = useNavigation();

    // useLocation 초기화 및 kakaoUser Object 받기
    const location = useLocation();
    const kakaoUser = location.state ? location.state.kakaoUser : null;

    // UseNavigate 초기화
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            // 만약, kakaoUser가 없다면
            if (kakaoUser == null) {
                try {
                    navigate('/login', { replace: true });
                } catch (e) {
                    console.log(e);
                }

            }
        }
    }, [navigate, kakaoUser, isInternalNavigation])

    // useState 초기화
    const [profile,] = useState(kakaoUser !== null ? kakaoUser.properties.profile_image : '');
    const [nickname, setNickname] = useState(kakaoUser !== null ? kakaoUser.properties.nickname : '');
    const [birthYear, setBirthYear] = useState('');
    const [birthMonth, setBirthMonth] = useState('');
    const [birthDay, setBirthDay] = useState('');
    const [sex, setSex] = useState(0);
    const [address, setAddress] = useState('');

    // 변수 설정
    const currentYear = new Date().getFullYear();
    const years = Array.from({ length: 101 }, (v, i) => currentYear - i);
    const months = Array.from({ length: 12 }, (v, i) => i + 1);
    const days = Array.from({ length: 31 }, (v, i) => i + 1);

    // CustomDropdown
    const [openDropdown, setOpenDropdown] = useState(null);

    const handleSelect = (option, id) => {
        if (id === '0') {
            setBirthYear(option);
        } else if (id === '1') {
            setBirthMonth(option);
        } else if (id === '2') {
            setBirthDay(option);
        } else if (id === '3') {
            setAddress(option);
        }

        setOpenDropdown(null);
    };

    const toggleDropdown = (id) => {
        if (openDropdown === id) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(id);
        }
    };

    // 제출 함수
    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            // 서류 동의 페이지로 이동
            if ([profile, birthYear, birthMonth, birthDay, sex, address].every(item => item !== '')) {
                try {
                    navigate('/consentform', {
                        replace: true,
                        state: {
                            uid: kakaoUser.id.toString(),
                            profile: profile,
                            nickname: nickname,
                            birthday: `${birthYear}-${birthMonth < 10 ? `0${birthMonth}` : birthMonth}-${birthDay < 10 ? `0${birthDay}` : birthDay}`,
                            sex: sex,
                            address: address,
                        }
                    });
                } catch (e) {
                    console.log(e);
                }

            }
        } catch (error) {
            console.error("Error adding document: ", error);
        }
    };

    return (
        <div className={styles.page}>
            <p className={styles.title}>
                정보를 입력해주세요
            </p>
            <div className='blank-40' />
            <form>
                <p className={styles.description}>닉네임</p>
                <div className='blank-10' />
                <input
                    className={styles.nickname_input}
                    type="text"
                    placeholder="닉네임"
                    value={nickname}
                    onChange={(e) => setNickname(e.target.value)}
                />
                <div className='blank-20' />
                <p className={styles.description}>생년월일</p>
                <div className='blank-10' />
                <div className={styles.birthday_select_container}>
                    <CustomDropdown
                        id="0"
                        isOpen={openDropdown}
                        options={years}
                        toggleDropdown={toggleDropdown}
                        onSelect={handleSelect}
                        placeholder="연"
                    />
                    <div className='blank-10' />
                    <CustomDropdown
                        id="1"
                        isOpen={openDropdown}
                        options={months}
                        toggleDropdown={toggleDropdown}
                        onSelect={handleSelect}
                        placeholder="월"
                    />
                    <div className='blank-10' />
                    <CustomDropdown
                        id="2"
                        isOpen={openDropdown}
                        options={days}
                        toggleDropdown={toggleDropdown}
                        onSelect={handleSelect}
                        placeholder="일"
                    />
                </div>
                <div className='blank-20' />
                <p className={styles.description}>성별</p>
                <div className='blank-10' />
                <div className={styles.sex_btn_container}>
                    <button className={sex === 0 ? styles.sex_btn : styles.sex_btn_unselected} type="button" key={0} onClick={() => setSex(0)}>
                        남
                    </button>
                    <div className='blank-10' />
                    <button className={sex === 1 ? styles.sex_btn : styles.sex_btn_unselected} type="button" key={1} onClick={() => setSex(1)}>
                        여
                    </button>
                </div>
                <div className='blank-20' />
                <p className={styles.description}>거주지역</p>
                <div className='blank-10' />
                <CustomDropdown
                    id="3"
                    className={address ? `selected ${styles.address_select}` : styles.address_select}
                    isOpen={openDropdown}
                    options={jsonData}
                    toggleDropdown={toggleDropdown}
                    onSelect={handleSelect}
                    placeholder="거주 지역"
                />
                <div className='blank-40' />
                <button onClick={handleSubmit} className={[nickname, birthYear, birthMonth, birthDay, sex, address].every(item => item !== '') ? styles.submit_btn_active : styles.submit_btn_inactive} type="submit">확인</button>
            </form>
        </div>
    );
};

export default SignUp;
