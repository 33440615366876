export const calculateSexDistribution = (sexList) => {
    const sexDistribution = {
        '남': 0,
        '여': 0,
    };

    sexList.forEach(sex => {
        if (sex === 0) {
            sexDistribution['남'] += 1;
        }else{
            sexDistribution['여'] += 1;
        }
    })

    return sexDistribution;
}