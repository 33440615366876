import React, { useEffect, useState } from "react";
import styles from './AnalyticsMain.module.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { SiGoogleanalytics } from "react-icons/si";
import { useAnswers } from "../../contexts/AnswerContext";
import { useNavigation } from "../../contexts/NavigationContext";

const AnalyticsMain = () => {
    const { isInternalNavigation } = useNavigation();

    // Provider
    const { getAnswers } = useAnswers();
    // Router
    const navigate = useNavigate();
    const location = useLocation();
    const data = location.state ? location.state : null;

    // useState
    const [isVote, setIsVote] = useState(false);
    const [currentNum, setCurrentNum] = useState(0);
    const [post, setPost] = useState({ options: [] });
    const [answers, setAnswers] = useState([]);
    const [optionCounts, setOptionCounts] = useState([]);
    const [totalCount, setTotalCount] = useState(0);

    // useEffect
    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            const fetchData = async () => {
                let isAllDataLoaded = data && data.post;
                if (!isAllDataLoaded) return;
                if (data.post.type !== "vote" && !data.answers) return;

                const newIsVote = data.post.type === "vote";
                const post = data.post;
                const newAnswers = newIsVote ? await getAnswers(post) : data.answers;
                if (newIsVote) {
                    setOptionCounts([
                        post.firstOptionCount,
                        post.secondOptionCount,
                        post.thirdOptionCount,
                        post.fourthOptionCount,
                        post.fifthOptionCount,
                    ]);
                    setTotalCount(post.totalCount);
                } else {
                    if (data.currentNum) {
                        setCurrentNum(data.currentNum);
                    }
                    let newOptionCounts = [0, 0, 0, 0, 0];
                    let newTotalCount = 0;
                    newAnswers.forEach(answer => {
                        const answerObj = JSON.parse(answer);
                        newOptionCounts[answerObj.answer[post.id]] += 1;
                        newTotalCount += 1;
                    })
                    setOptionCounts(newOptionCounts);
                    setTotalCount(newTotalCount);
                }
                setIsVote(newIsVote);
                setPost(post);
                setAnswers(newAnswers);
            };

            fetchData();
        }
    }, [navigate, isInternalNavigation, data, getAnswers]);

    // handleClick
    const handleClick = (index) => {
        navigate('/analytics', {
            state: {
                post: post,
                currentIndex: index,
                answers: answers,
            }
        })
    }

    const calculatePercentage = (optionCount, totalCount) => {
        if (typeof optionCount !== 'number' || typeof totalCount !== 'number' || totalCount === 0) {
            // optionCount나 totalCount가 숫자가 아니거나, totalCount가 0일 때
            return 0; // NaN을 반환하지 않고, 대신 0%를 반환
        }
        return Math.floor((optionCount / totalCount) * 100);
    };

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>데이터 분석</p>
            <div className="blank-30"/>
            <div className={styles.post_container}>
                {isVote ? <p className={styles.post_type}>투표</p> :
                    <div className={styles.number_container}>
                        <p className={styles.number}>
                            {`Q${currentNum}`}
                        </p>
                    </div>}
                <div className='blank-10' />
                <p className={styles.post_title}>{post.title}</p>
                <div className='blank-20' />
                {post.options.map((option, i) => {
                    return (
                        <div key={option.id}>
                            <div className={styles.post_option_container}>
                                {option.image !== '' ? <img src={option.image} alt={option.content} className={styles.post_option_image} /> :
                                    <div className={styles.post_option_image_blank} />}
                                <div className={styles.post_option_details}>
                                    <div className={styles.post_option_contentRow}>
                                        <p className={styles.post_option_text}>{option.content}</p>
                                        <div style={{ flex: 1 }}></div>
                                        <p className={styles.post_option_text}>
                                            {`${calculatePercentage(optionCounts[i], totalCount)}% (${optionCounts[i]})`}
                                        </p>
                                    </div>
                                    <div>
                                        <div className='blank-10' />
                                        <div className={styles.post_option_progressBar_container}>
                                            <div className={styles.post_option_progress_bar} style={{
                                                width: `${calculatePercentage(optionCounts[i], totalCount)}%`,
                                                opacity: `${optionCounts[i] >= totalCount / 2 ? 1 : 0.2}`
                                            }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type='button' className={styles.to_analytics_btn} onClick={() => handleClick(i)}>
                                <SiGoogleanalytics size={14} color="var(--sub-font-color)" />
                                <div className="blank-5" />
                                <p className={styles.to_analytics_btn_text}>통계 보러가기</p>
                            </button>
                        </div>
                    )
                })}
                <p className={styles.total_num}>{`${totalCount}명 투표`}</p>
            </div>
        </div>
    )
}

export default AnalyticsMain;