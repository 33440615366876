import React, { useEffect, useState } from "react";
import styles from './Setting.module.css';
import { useNavigate } from "react-router-dom";
import { useUser } from '../contexts/UserContext';
import { Response } from '../model/response/response';
import { useNavigation } from "../contexts/NavigationContext";

const Setting = () => {
    const { isInternalNavigation, setIsInternalNavigation } = useNavigation();

    // response
    const response = new Response();

    // useState
    const [isLoading, setIsLoading] = useState(false);

    // userProvider
    const { userData, signOutUserData, deleteUserData } = useUser();

    // useNavigate
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInternalNavigation) setIsInternalNavigation(true);
    }, [isInternalNavigation, setIsInternalNavigation])

    // 이벤트 핸들러가 함수를 반환하도록 수정
    const handleLinkClick = (type) => () => {
        navigate(`/document-${type}`);
    }

    const handleLogout = async () => {
        await signOutUserData();
        navigate('/', {
            replace: true
        });
    }

    const handleDeleteAccount = async () => {
        // 계정 삭제 처리
        if (isLoading) return;
        setIsLoading(true);

        try {
            if (userData !== null) {
                await response.deleteUser(userData.uid);
                deleteUserData();
                navigate('/', {
                    replace: true
                });
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    return (<div className={styles.page}>
        <p className={styles.page_name}>설정</p>
        <div className="blank-30" />
        <p className={styles.title}>약관 및 개인정보처리 방침</p>
        <div className="blank-20" />
        <button onClick={handleLinkClick('term')} className={styles.btn} type="button">
            <p className={styles.btn_text}>서비스 이용약관</p>
        </button>
        <div className="blank-20" />
        <button onClick={handleLinkClick('privacy')} className={styles.btn} type="button">
            <p className={styles.btn_text}>개인정보처리 방침</p>
        </button>
        <div className="blank-50" />
        {userData ? <div className={styles.account_container}>
            <p className={styles.title}>계정관리</p>
            <div className="blank-20" />
            <button onClick={handleLogout} className={styles.btn} type="button">
                <p className={styles.btn_text}>로그아웃</p>
            </button>
            <div className="blank-20" />
            <button onClick={handleDeleteAccount} className={styles.btn} type="button">
                <p className={styles.btn_text} style={!isLoading ? {} : { display: 'none' }}>회원탈퇴</p>
                <div className={styles.loader} style={isLoading ? {} : { display: 'none' }}></div>
            </button>
        </div> : null}
    </div>)
}

export default Setting;
