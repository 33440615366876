// ✅ GPT 코드리뷰
import React from 'react';
import styles from './CustomCheckBox.module.css'; // CSS 파일을 import합니다.
import { FaCheckCircle } from 'react-icons/fa'; // 아이콘 import

const CustomCheckbox = ({ checked, onChange }) => {
  return (
    <div className={styles.custom_checkbox} onClick={onChange}>
      <FaCheckCircle 
        className={`${styles.checkbox_icon} ${checked ? styles.checked : ''}`}
        style={{ opacity: checked ? 1 : 0.2 }}
      />
    </div>
  );
};

export default CustomCheckbox;