// ✅ GPT 코드리뷰
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './CreateComplete.module.css';
import {useNavigation} from '../../contexts/NavigationContext';

const CreateComplete = () => {
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInternalNavigation){
            navigate('/', {
                replace: true
            });
        }
    }, [navigate, isInternalNavigation]);

    // next_btn Click
    const handleClick = () => {
        try {
            navigate('/', {
                replace: true
            });
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <div className={styles.page}>
            <div className='blank-100' />
            <div className={styles.main_message}>게시물 업로드 완료</div>
            <div className='blank-100' />
            <button className={styles.next_btn} onClick={handleClick}>확인</button>
        </div>
    );
}

export default CreateComplete;
