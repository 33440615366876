import React, { useEffect } from "react";
import styles from './CreateMain.module.css';
import { MdHowToVote } from "react-icons/md";
import { RiSurveyLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useNavigation } from "../../contexts/NavigationContext";

const CreateMain = () => {
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        }
    }, [navigate, isInternalNavigation])

    const handleClick = (type) => {
        if (type === 'vote') {
            navigate('/create-vote');
        } else {
            navigate('/create-survey')
        }
    }

    return (<div className={styles.page}>
        <div className={styles.container}>
            <p className={styles.page_name}>게시물 만들기</p>
            <div className="blank-30"/>
            <button onClick={() => { handleClick('vote') }} className={styles.btn} type="button">
                <MdHowToVote color="rgba(10, 10, 10, 1)" size={28} />
                <div className="blank-10" />
                <p className={styles.btn_text}>투표 만들기</p>
            </button>
            <div className="blank-20" />
            <button onClick={() => { handleClick('survey') }} className={styles.btn} type="button">
                <RiSurveyLine color="rgba(10, 10, 10, 1)" size={28} />
                <div className="blank-10" />
                <p className={styles.btn_text}>설문조사 만들기</p>
            </button>
        </div>
    </div>);
}

export default CreateMain;