import React from 'react';
import NavBar from './CustomNavBar';

function Layout({ children }) {
  return (
    <div>
      <NavBar />
      <div style={{paddingTop: 50}}>
        {children} {/* 페이지 내용이 이 위치에 렌더링됩니다. */}
      </div>
    </div>
  );
}

export default Layout;
