// ✅ GPT 코드리뷰
import React, { useEffect, useState } from 'react';
import styles from './SurveyPost.module.css';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserContext';
import { usePosts } from '../contexts/PostContext';
import { TiDelete } from "react-icons/ti";
import DeleteConfirmModal from './modal/DeleteConfirmModal';
import { IoIosArrowForward } from "react-icons/io";

const SurveyPost = ({ surveyData }) => {
    const navigate = useNavigate();
    const { userData } = useUser();
    const { deletePostIdById } = usePosts();
    const [participatedPostInfosMap, setParticipatedPostInfosMap] = useState({});
    const [isModalOpen, setIsModalOpen] = useState(false);

    useEffect(() => {
        if (userData) {
            const tempMap = userData.participatedPostInfos.reduce((acc, item) => {
                const [postId, choiceIndex] = item.split('/');
                acc[postId] = Number(choiceIndex);
                return acc;
            }, {});
            setParticipatedPostInfosMap(tempMap);
        }
    }, [userData]);

    const isParticipatedSurveyPost = participatedPostInfosMap.hasOwnProperty(surveyData.id);

    const handleClick = () => {
        try {
            navigate('/survey', {
                state: {
                    surveyData: surveyData
                }
            });
        } catch (e) {
            console.log(e);
        }
    };

    if (!surveyData) {
        return null;
    }

    const handleDeleteBtnClick = () => {
        setIsModalOpen(true);
    };

    const handleConfirmDelete = async () => {
        await deletePostIdById(surveyData.id);
        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleToAnalyticsPage = () => {
        navigate('/survey-total-view', {
            state: {
                post: surveyData
            }
        })
    }

    return (<div className={styles.page}>
        <div className={styles.container}>
            {/* 게시글 삭제 버튼(관리자 전용) */}
            {userData && userData.isAdmin ?
                <div type="button" className={styles.delete_btn} onClick={handleDeleteBtnClick}>
                    <TiDelete size={32} color="rgba(10, 10, 10, 0.3)" />
                </div> : null
            }
            <DeleteConfirmModal post={surveyData} isOpen={isModalOpen} onClose={handleCloseModal} onDelete={handleConfirmDelete} />
            {/* 게시글 분석 버튼(관리자 전용) */}
            {userData && userData.isAdmin ?
                <div type="button" className={styles.to_analytics_page_btn} onClick={handleToAnalyticsPage}>
                    <p className={styles.to_analytics_page_btn_text}>분석 보러가기</p>
                    <div className='blank-5'/>
                    <IoIosArrowForward size={18} color="var(--main-font-color)"/>
                </div> : null
            }
            <p className={styles.type}>설문조사</p>
            <div className='blank-10' />
            <p className={styles.title}>{surveyData.title}</p>
            <div className='blank-20' />
            <img src={surveyData.thumbnailImage} alt="설문조사 썸네일" className={styles.thumbnail_image} />
            <div className='blank-20' />
            <button
                className={styles.next_btn}
                type='button'
                style={{ opacity: isParticipatedSurveyPost ? 0.2 : 1 }}
                onClick={() => {
                    if (!userData) {
                        alert('로그인 후, 이용할 수 있습니다');
                        try {
                            navigate('/login');
                        } catch (e) {
                            console.log(e);
                        }
                    } else if (!isParticipatedSurveyPost) {
                        handleClick();
                    }
                }}
            >
                {isParticipatedSurveyPost ? '답변완료' : '답변하기'}
            </button>
            <div className='blank-20' />
            <p className={styles.total_num}>{`${surveyData.totalCount}명 참여`}</p>
        </div>
    </div>

    );
};

export default SurveyPost;