import React, { useEffect, useState } from 'react';
import styles from './SurveyEssay.module.css';
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";
import { TiDelete } from "react-icons/ti";

// TODO
const SurveyEssay = ({ currentTitle, currentNum, 
    isPrevExist, isNextExist, notifyParent, 
    onClickPrevBtn, onClikNextBtn, onClickDeleteBtn }) => {
    const [title, setTitle] = useState('');

    useEffect(() => {
        setTitle(currentTitle);
    }, [currentTitle])

    const handleChange = (value) => {
        notifyParent(value);
        setTitle(value);
    }

    return (
        <div className={styles.container}>
            <div className={styles.number_container}>
                {!isPrevExist || <div className={styles.prev_btn} onClick={onClickPrevBtn}>
                    <IoIosArrowBack color='rgba(10, 10, 10, 1)' size={24} />
                </div>}
                {!isPrevExist || <div className='blank-10' />}
                <p className={styles.number}>
                    {`Q${currentNum}`}
                </p>
                {!isNextExist || <div className='blank-10' />}
                {!isNextExist || <div className={styles.next_btn} onClick={onClikNextBtn}>
                    <IoIosArrowForward color='rgba(10, 10, 10, 1)' size={24} />

                </div>}
                <div className={styles.blank_fill} />
                <button className={styles.question_delete_btn} onClick={onClickDeleteBtn}>
                    <TiDelete size={30} color="rgba(10, 10, 10, 1)" />
                </button>
            </div>
            <div className='blank-20' />
            <input className={styles.title_input}
                value={title}
                onChange={(e) => { handleChange(e.target.value) }}
                placeholder='제목을 작성해주세요' />
            <div className='blank-50' />
            <div className={styles.input_container}>
                <p className={styles.input_container_text}>
                    답변을 적어주세요.
                </p>
            </div>
        </div>
    )
}

export default SurveyEssay;