import React, { useEffect, useState } from 'react';
import styles from './SurveyThumbnail.module.css';
import { BsPlusCircle } from "react-icons/bs";

const SurveyThumbnail = ({ currentTitle, currentImage, notifyParent }) => {
    const [title, setTitle] = useState('');
    const [, setImage] = useState(null);
    const [imageUrl, setImageUrl] = useState('');

    useEffect(() => {
        setTitle(currentTitle);
        setImageUrl(currentImage);
    }, [currentTitle, currentImage])

    const handleImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0];
            const newImageUrl = URL.createObjectURL(file);

            setImage(file);
            setImageUrl(newImageUrl);
        }
    };

    return (
        <div className={styles.container}>
            <p className={styles.type}>설문조사</p>
            <div className="blank-10" />
            <input className={styles.title_input}
                type="text"
                placeholder="제목을 작성해주세요"
                value={title}
                onChange={(e) => setTitle(e.target.value)} />
            <div className="blank-20" />
            <div className={styles.image_uploader_container}>
                <label className={styles.image_uploader_label}>
                    {imageUrl ? (
                        <img className={styles.image_uploader_image} src={imageUrl} alt="Uploaded" />
                    ) : (
                        <BsPlusCircle color='rgba(10, 10, 10, 0.3)' size={50} />
                    )}
                    <input className={styles.image_uploader_input} type="file" accept="image/*" onChange={handleImageChange} style={{ display: 'none' }} />
                </label>
            </div>
            <div className='blank-20' />
            <button
                onClick={() => {
                    if (title !== '' && imageUrl !== '') {
                        notifyParent(title, imageUrl);
                    }
                }}
                className={styles.submit_btn}
                type="button"
                style={{ opacity: `${title !== '' && imageUrl !== '' ? '1' : '0.1'}` }}
            >
                질문 만들기
            </button>
        </div>
    )
}


export default SurveyThumbnail;