import React, { useEffect, useState } from 'react';
import { usePosts } from './../contexts/PostContext';
import SurveyPost from '../component/SurveyPost';
import VotePost from '../component/VotePost';
import styles from './Main.module.css';
import { IoIosMenu } from "react-icons/io";
import { MdHowToVote } from "react-icons/md";
import { RiSurveyLine } from "react-icons/ri";
import { useNavigation } from '../contexts/NavigationContext';
import { useLocation } from 'react-router-dom';

const Main = () => {
    const location = useLocation();
    const { isInternalNavigation, setIsInternalNavigation } = useNavigation();

    // useState
    const [isLoading, setIsLoading] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(0);

    // postProvider
    const { posts, loadMorePosts, isLastPost } = usePosts();

    useEffect(() => {
        if (!isInternalNavigation) setIsInternalNavigation(true);
        setCurrentCategory(0);
    }, [isInternalNavigation, setIsInternalNavigation, location.search]);

    const handleLoadMorePosts = async () => {
        // 필터별로 불러오기
        if (isLoading) return;
        setIsLoading(true);

        try {
            await loadMorePosts(currentCategory, false);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const postsFiltered = () => {
        return posts.filter((item) => {
            if (!item) return false; // 빈 항목은 제거
            if (currentCategory === 0) {
                // 전체보기
                return true;
            } else if (currentCategory === 1 && item.type === 'vote') {
                // 투표 게시글만
                return true;
            } else if (currentCategory === 2 && item.type === 'survey') {
                // 설문조사 게시글만
                return true;
            }
            return false; // 그 외의 경우는 제외
        });
    };

    const handleCategory = (type) => {
        setCurrentCategory(type);
    }

    return (
        <div className={styles.page}>
            <div className={styles.category_container}>
                <button type='button' style={{ opacity: currentCategory === 0 ? 1 : 0.3 }} className={styles.category_item_btn} onClick={() => handleCategory(0)}>
                    <IoIosMenu size={24} color='var(--main-color)'/>
                    <div className='blank-5' />
                    <p className={styles.category_item_text}>전체보기</p>
                </button>
                <button type='button' style={{ opacity: currentCategory === 1 ? 1 : 0.3 }} className={styles.category_item_btn} onClick={() => handleCategory(1)}>
                    <MdHowToVote size={24} color='var(--main-color)'/>
                    <div className='blank-5' />
                    <p className={styles.category_item_text}>투표</p>
                </button>
                <button type='button' style={{ opacity: currentCategory === 2 ? 1 : 0.3 }} className={styles.category_item_btn} onClick={() => handleCategory(2)}>
                    <RiSurveyLine size={24} color='var(--main-color)'/>
                    <div className='blank-5' />
                    <p className={styles.category_item_text}>설문조사</p>
                </button>
            </div>
            <div className='blank-50' />
            <div className={styles.post_container}>
                {postsFiltered().map((item) => {
                    if (!item) return null;

                    if (item.type === 'survey') {
                        return (
                            <SurveyPost
                                key={item.id}
                                surveyData={item}
                            />
                        );
                    } else {
                        return (
                            <VotePost
                                key={item.id}
                                voteData={item} />
                        );
                    }
                })}
                <div className='blank-20' />
                {isLastPost[currentCategory] === true || <button onClick={handleLoadMorePosts} className={styles.load_more_posts_btn} type="button">
                    <p className={styles.load_more_posts_btn_text} style={!isLoading ? {} : { display: 'none' }}>더 불러오기</p>
                    <div className={styles.loader} style={isLoading ? {} : { display: 'none' }}></div>
                </button>}
                <div className='blank-100'/>
            </div>
        </div>
    );
}

export default Main;
