import jsonData from '../data/koreaRegions.json';

export const calculateAddressDistribution = (addressList) => {
    let addressDistribution = {};
    jsonData.forEach(item => {
        addressDistribution[item] = 0;
    })

    addressList.forEach(address => {
        addressDistribution[address] += 1;
    })

    return addressDistribution;
}