import React, { useState, useEffect } from 'react';
import styles from './SurveyMultipleChoice.module.css';

const SurveyMultipleChoice = ({ data, num, leftNum, onClickPrevBtn, onClickEvent, answers }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
    const options = data.options;

    useEffect(() => {
        setSelectedIndex(answers[data.id]);
    }, [answers, data.id]);

    return (
        <div className={styles.container}>
            <div className={styles.num_container}>
                <p className={styles.num}>{`Q${num+1}`}</p>
                <div className={styles.blank} />
                <p className={styles.left_num}>{`${leftNum}개 남음`}</p>
            </div>
            <div className='blank-20' />
            <p className={styles.title}>{data.title}</p>
            <div className='blank-50' />
            <div className={styles.options_container}>
                {
                    options.map(
                        (option, index) => {
                            return <div
                                className={styles.option_btn}
                                key={index}
                                onClick={() => setSelectedIndex(index)}
                                style={{ backgroundColor: selectedIndex !== index || "var(--main-color)", color: selectedIndex !== index || "#ffffff" }} >
                                {option.image === '' || <img className={styles.option_image} src={option.image} alt={option.content} />}
                                <div className='blank-20' />
                                <p className={styles.option_text}>{option.content}</p>
                            </div>
                        }
                    )
                }
            </div>
            <div className='blank-10' />
            <div className={styles.btn_container}>
                {num === 0 || <button
                    type='button'
                    className={styles.prev_btn}
                    onClick={() => onClickPrevBtn(data.id, selectedIndex)}>
                    이전
                </button>}
                {num === 0 || <div className='blank-20' />}
                <button
                    type='button'
                    className={styles.next_btn}
                    style={{ opacity: selectedIndex === null ? 0.2 : 1 }}
                    onClick={() => {
                        if (selectedIndex !== null) {
                            onClickEvent(data.id, selectedIndex);
                        }
                    }}
                    disabled={selectedIndex === null}
                    aria-disabled={selectedIndex !== null ? "true" : "false"}>
                    {leftNum !== 0 ? "다음" : "제출하기"}
                </button>
            </div>

        </div>
    );
};

export default SurveyMultipleChoice;
