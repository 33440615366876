import React from 'react';
import styles from './Document.module.css';


const DocumentPrivacy = () => {

  return (
    <div className={styles.page} >
      <p className={styles.title}>동서남북 홈페이지 개인정보 처리방침</p>
      <p className={styles.content}>
      <br/>
        1. 수집하는 개인정보 항목 및 수집 방법<br/>
        가. 수집하는 개인정보의 항목<br/>
        성별<br/>
        사는 지역<br/>
        닉네임<br/>
        생년월일<br/>
        나. 수집 방법<br/>
        회원가입 시 이용자가 직접 제공하는 경우<br/>
        카카오 로그인 시 제공되는 정보<br/>
        <br/>
        2. 개인정보의 수집 및 이용목적<br/>
        서비스 제공, 운영 및 개선<br/>
        투표, 설문조사에 참여하기 위한 식별 및 통계 목적<br/>
        고객상담 및 문의 응대<br/>
        <br/>
        3. 개인정보의 보유 및 이용기간<br/>
        이용자가 회원 탈퇴를 요청하지 않는 한, 회원 탈퇴 시까지 보유 및 이용됩니다.<br/>
        <br/>
        4. 개인정보의 제3자 제공 및 공개<br/>
        투표, 설문조사 데이터는 통계 작성을 위해 제3자에게 제공되며, 모두에게 공개될 수 있습니다.<br/>
        <br/>
        5. 개인정보의 파기절차 및 방법<br/>
        개인정보의 수집 및 이용목적이 달성된 경우, 해당 정보는 즉시 파기됩니다.<br/>
        <br/>
        6. 개인정보보호를 위한 조치<br/>
        개인정보를 적법하고 안전하게 관리하기 위해 최선의 노력을 다하고 있습니다.<br/>
        개인정보의 누출, 변조, 훼손을 방지하기 위한 노력을 기울이고 있습니다.<br/>
        <br/>
        7. 이용자의 권리와 행사방법<br/>
        이용자는 언제든지 자신의 개인정보를 열람, 정정, 삭제할 수 있습니다.<br/>
        개인정보에 대한 접근 및 수정은 홈페이지 내 개인정보 관리 페이지에서 직접 처리할 수 있습니다.<br/>
        <br/>
        8. 개인정보 관리책임자<br/>
        개인정보 보호를 위해 관리책임자를 지정하여 운영하고 있으며, 문의 및 불만처리를 담당하고 있습니다.<br/>
        <br/>
        9. 개인정보처리방침의 변경<br/>
        법령의 개정 또는 회사의 정책 변화에 따라 개인정보처리방침이 변경될 수 있으며, 변경 사항은 홈페이지를 통해 사전 공지됩니다.<br/>
        <br/>
        10. 개인정보 관련 문의 및 불만처리<br/>
        개인정보 관련 문의 및 불만처리는 회사 이메일 iillllilil@nate.com 을통해 접수해주세요<br/>
      </p>
      <div className='blank-100'/>
    </div>
  );
}

export default DocumentPrivacy;

