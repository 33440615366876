// ✅ GPT 코드리뷰
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Complete.module.css';
import { useNavigation } from '../contexts/NavigationContext';

const Complete = () => {
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        }
    }, [navigate, isInternalNavigation]);

    // next_btn Click
    const handleClick = () => {
        try {
            navigate('/', {
                replace: true
            });
        } catch (e) {
            console.log(e);
        }

    }

    return (
        <div className={styles.page}>
            <div className='blank-100' />
            <p className={styles.description}>설문조사 제출 완료</p>
            <div className='blank-20' />
            <div className={styles.main_message}>{`참여해주셔서\n감사합니다`}</div>
            <div className='blank-100' />
            <button className={styles.next_btn} onClick={handleClick}>확인</button>
        </div>
    );
}

export default Complete;
