import React from 'react';
import styles from './Document.module.css';


const DocumentTerm = () => {

  return (
    <div className={styles.page} >
      <p className={styles.title}>동서남북 설문조사 페이지 이용약관</p>
      <p className={styles.content}>
        <br />
        제 1 조 (목적)<br />
        이 약관은 이용자가 동서남북(이하 ‘커뮤니티’라 합니다)이 운영하는 설문조사 페이지를 이용함에 있어 ‘커뮤니티’와 이용자 간의 권리·의무 및 책임사항, 기타 필요한 사항을 규정하는 것을 목적으로 합니다.<br />
        <br />
        제 2 조 (정의)<br />
        이 약관에서 사용하는 용어의 정의는 다음 각호와 같습니다.<br />
        1. '이용자'라 함은 '커뮤니티' 사이트에 접속하여 본 약관에 동의하고 설문조사 페이지 내 서비스를 이용하려는 자를 말합니다.<br />
        2. ‘서비스’라 함은 투표, 설문조사에 참여하고, 투표나 설문조사 통계를 열람할 수 있는 서비스를 말합니다.<br />
        3. 기타 이 약관에서 정하지 않은 용어는 관련 법령 및 일반 상관례에 따릅니다.<br />
        <br />
        제 3 조 (약관의 효력 및 변경)<br />
        (1) 이 약관의 내용은 서비스 화면에 게시하거나 기타의 방법으로 회원에게 공지함으로써 그 효력이 발생합니다.<br />
        (2) '커뮤니티'는 필요한 경우 관련 법령을 위배하지 않는 범위 내에서 이 약관을 개정할 수 있습니다. 이 약관이 개정되는 경우 '커뮤니티'는 변경사항을 시행일자 7일 전부터 공지하는 것을 원칙으로 하며, ' 이용자'에게 불리하게 약관의 내용을 변경하는 경우 최소한 30일 이전에 공지합니다.<br />
        (3) '회원'은 변경된 약관에 동의하지 않을 권리가 있으며, 변경된 약관에 동의하지 않을 경우에는 서비스 이용을 중단하고 탈퇴할 수 있습니다. 다만, '이용자'가 제3항의 방법 등으로 '커뮤니티'가 별도 고지 한 약관 개정 공지 기간 내에 '커뮤니티'에 개정 약관에 동의하지 않는다는 명시적인 의사표시를 하지 않는 경우 변경된 약관에 동의한 것으로 간주합니다.<br />
        (4) 이 약관에 규정되지 않은 사항에 대해서는 관련 법령 또는 '커뮤니티'가 정한 개별 서비스의 이용약 관, 운영정책 및 규칙 등(이하 '세부지침'이라 합니다)의 규정에 따릅니다.<br />
        <br />
        제 4 조 (회원가입절차)<br />
        (1) 이 약관은 ‘이용자’가 이 약관을 읽은 후 “동의하기” 단추를 눌렀을 때 동의된 것으로 간주합니다.<br />
        (2) 서비스 이용을 위한 회원가입은, 카카오톡으로 로그인 한 후 닉네임과 생년월일, 성별, 사는지역 등을 포함한 필수사항을 입력한 다음 이 약관을 읽고 숙지한 후 “동의하기” 버튼을 누름으로써 완료됩니 다. 다만, ‘커뮤니티’가 필요하다고 인정하는 경우 ‘이용자’에게 별도의 서류를 제출하도록 할 수 있습니다.<br />
        <br />
        제 5 조 (이용자의 권리)<br />
        (1) 이용자는 서비스를 이용하여 투표, 설문조사에 참여할 수 있습니다. 단, 투표 및 설문조사의 생성 및 수정 권한은 서비스 제공자인 ‘커뮤니티’에 있습니다.<br />
        (2) 이용자는 자신이 참여했던 투표 및 설문조사를 열람할 수 있습니다.<br />
        <br />
        제 6 조 (이용자의 의무)<br />
        이용자는 서비스를 이용함에 있어 다른 이용자의 권리를 침해하여서는 안 됩니다.<br />
        <br />
        제 7 조 (서비스의 제공)<br />
        (1) ‘커뮤니티’는 이용자에게 투표, 설문조사 기능을 제공합니다.<br />
        (2) ‘커뮤니티’는 투표, 설문조사 데이터를 수집하여 통계를 작성하고, 이를 제3자에게 제공할 수 있으며, 모두에게 공개될 수도 비공개될 수도 있습니다.<br />
        <br />
        제 8 조 (관리자 권한)<br />
        (1) 관리자는 투표, 설문조사 개시물을 삭제하거나 생성할 수 있습니다.<br />
        (2) 관리자는 서비스의 원활한 운영을 위해 필요한 조치를 취할 수 있습니다.<br />
        <br />
        제 9 조 (개인정보의 보호)<br />
        (1) 서비스 제공자는 이용자의 개인정보를 적법하고 안전하게 관리합니다.<br />
        (2) 개인정보의 수집, 이용, 제공에 관한 사항은 별도의 개인정보 처리방침에 따릅니다.<br />
        <br />
        제 10 조 (분쟁의 해결)<br />
        (1) 본 약관에 관한 분쟁은 서비스 제공자와 이용자 간의 합의에 따라 원만히 해결합니다.<br />
        (2) '커뮤니티'와 '이용자' 간 서비스 이용으로 발생한 분쟁에 대해 소송이 제기될 경우 관할법원은 민사 소송법이 정하는 바에 따릅니다.
      </p>
      <div className="blank-100"/>
    </div>
  );
}

export default DocumentTerm;
