// ✅ GPT 코드리뷰
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './ConsentForm.module.css';
import { IoIosArrowForward } from 'react-icons/io';
import CustomCheckbox from '../../component/CustomCheckBox';
import { User } from '../../model/response/user';
import { Response } from '../../model/response/response';
import { useUser } from './../../contexts/UserContext';
import { useNavigation } from '../../contexts/NavigationContext';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../firebase';

const ConsentForm = () => {
  const { isInternalNavigation } = useNavigation();

  // useUser 초기화
  const { initializeUserData } = useUser();

  // Router
  const location = useLocation();
  const data = location.state ? location.state : null;
  const isAllDataLoaded = [data.uid, data.profile, data.nickname, data.birthday, data.sex, data.address].every(item => item !== null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInternalNavigation) {
      navigate('/', {
        replace: true
      });
    } else {
      if (data == null || !isAllDataLoaded) {
        try {
          navigate('/login', { replace: true });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [navigate, isInternalNavigation, data, isAllDataLoaded]);

  // Response
  const response = new Response();

  // State
  const [agreeds, setAgreeds] = useState({
    term: false,
    privacy: false
  });
  const [isLoading, setIsLoading] = useState(false);

  // Checkbox Update
  const handleCheckboxChange = (name) => {
    setAgreeds(prev => ({
      ...prev,
      [name]: !prev[name]
    }));
  };

  // click next button
  const handleAllAgree = async () => {
    setAgreeds({
      term: true,
      privacy: true
    })

    const newUser = new User(
      {
        uid: data.uid,
        profile: data.profile,
        nickname: data.nickname,
        email: 'unknown',
        birthday: data.birthday,
        sex: data.sex,
        address: data.address,
        participatedPostInfos: [],
        isServiceTermsAgreed: true,
        isPrivacyPolicyAgreed: true,
        isAdmin: false,
      }
    );

    try {
      setIsLoading(true);

      // Firebase Auth 가입
      const email = `${data.uid}@gmail.com`; // 이메일 형식 설정
      const password = 'ehdtjskaqnr'; // 비밀번호 설정

      // Firebase Authentication을 사용하여 사용자 가입 시도
      await createUserWithEmailAndPassword(auth, email, password);
      // 가입 성공 후 로그인
      await signInWithEmailAndPassword(auth, email, password);

      const isSuccess = await response.createUser(newUser.toJson());
      if (isSuccess) {
        // localStorage uid 저장
        localStorage.setItem('uid', newUser.uid);
        // UserProvider userData 저장
        initializeUserData(newUser);
        navigate('/welcome', {
          replace: true,
          state: {
            message: {
              main_message: `${newUser.nickname}님,\n환영합니다`,
              description: "회원가입 완료",
            },
            userData: newUser.toJson()
          }
        });
      }
    } catch (e) {
      navigate('/login', {
        replace: true
      });
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  // Navigate link
  const handleLinkClick = (e, id) => {
    e.stopPropagation(); // 이벤트 버블링 방지
    let link = `/document-${id}`;
    try {
      navigate(link);
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <div className={styles.page}>
      <p className={styles.title}>항목에 동의해주세요</p>
      <div className='blank-10' />
      <p className={styles.description}>서비스 이용을 위한 필수 동의 항목입니다</p>
      <div className='blank-50' />
      <div className={styles.terms_container}>
        <CustomCheckbox
          checked={agreeds.term}
          onChange={() => handleCheckboxChange('term')}
          label="term"
        />
        <div className='blank-10' />
        <p className={styles.terms_text} onClick={(e) => handleLinkClick(e, 'term')} >[필수] 서비스 이용약관</p>
        <div className='blank-10' />
        <IoIosArrowForward className={styles.terms_icon} onClick={(e) => handleLinkClick(e, 'term')} />
      </div>
      <div>
        <div className={styles.terms_container}>
          <CustomCheckbox
            checked={agreeds.privacy}
            onChange={() => handleCheckboxChange('privacy')}
            label="privacy"
          />
          <div className='blank-10' />
          <p className={styles.terms_text} onClick={(e) => handleLinkClick(e, 'privacy')} >[필수] 개인정보처리방침</p>
          <div className='blank-10' />
          <IoIosArrowForward className={styles.terms_icon} onClick={(e) => handleLinkClick(e, 'privacy')} />
        </div>
        <div className='blank-100' />
        <button onClick={isLoading ? () => { } : handleAllAgree} className={styles.next_btn} type="button">
          <p className={styles.next_btn_text} style={!isLoading ? {} : { display: 'none' }}>모두 동의하기</p>
          <div className={styles.loader} style={isLoading ? {} : { display: 'none' }}></div>
        </button>
      </div>
    </div>
  );
};

export default ConsentForm;
