import React, { useState, useEffect } from 'react'; // useEffect를 추가합니다.
import styles from './DeleteConfirmModal.module.css';
import CircleLoader from '../animation/CircleLoader';

const DeleteConfirmModal = ({ post, isOpen, onClose, onDelete }) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // 오버레이 클릭을 처리하는 함수
    const handleOverlayClick = (event) => {
      // 클릭 이벤트가 오버레이에서 발생했는지 확인
      if (event.target.classList.contains(styles.modal_overlay)) {
        onClose(); // 모달 닫기
      }
    };

    if (isOpen) {
      // 모달이 열려있다면, 오버레이 클릭 이벤트 리스너를 추가
      window.addEventListener('click', handleOverlayClick);
    }

    // 컴포넌트가 언마운트되거나 모달이 닫힐 때 이벤트 리스너를 제거
    return () => {
      window.removeEventListener('click', handleOverlayClick);
    };
  }, [isOpen, onClose]); // 의존성 배열에 isOpen과 onClose 추가

  if (!isOpen) return null;

  const handleClick = () => {
    setIsLoading(true);
    onDelete();
  };

  return (
    <div className={styles.modal_overlay}>
      <div className={styles.modal_content}>
        <h2>{`${post.type === 'vote' ? '(투표)' : '(설문조사)'} '${post.title}'을(를) 삭제하시겠어요? `}</h2>
        <div className="blank-50" />
        <div className={styles.btn_container}>
          <button onClick={handleClick}>{isLoading ? <CircleLoader size={24} color='#ffffff' width={4}/> : '네'}</button>
          <div className="blank-20" />
          <button onClick={onClose} style={{ backgroundColor: 'var(--sub-10-color)', color: 'var(--main-font-color)' }}>아니요</button>
        </div>
      </div>
    </div>
  );
};

export default DeleteConfirmModal;
