import React from "react";
import styles from "./EssayView.module.css";
import { IoIosArrowForward } from "react-icons/io";

const EssayView = ({ currentNum, essay, essayAnswers }) => {
    // essay<Object>
    // essayAnswers<Array<string>>

    return (
        <div className={styles.container}>
            <div className={styles.to_detail_container}>
                <p className={styles.to_detail_text}>자세히 보기</p>
                <div className='blank-5' />
                <IoIosArrowForward size={18} color="var(--main-font-color)" />
            </div>
            <div className={styles.number_container}>
                <p className={styles.number}>
                    {`Q${currentNum}`}
                </p>
            </div>
            <div className='blank-20' />
            <p className={styles.title}>
                {essay.title}
            </p>
            <div className='blank-50' />
            <div className={styles.answer_section}>
                {(essayAnswers.length > 3 ? essayAnswers.slice(0, 3) : essayAnswers).map((answer, i) => {
                    return (
                        <div key={`answer-${i}`} className={styles.answer_container} style={{ marginBottom: i === essayAnswers.length - 1 || i === 2 ? 0 : 10 }}>
                            <p className={styles.answer_text}>
                                {answer}
                            </p>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default EssayView;