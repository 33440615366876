import React, { useEffect, useState } from 'react';
import { usePosts } from '../contexts/PostContext';
import SurveyEssay from '../component/SurveyEssay';
import SurveyMultipleChoice from '../component/SurveyMutipleChoice';
import { useLocation, useNavigate } from 'react-router-dom';
import { useNavigation } from '../contexts/NavigationContext';
import styles from './Survey.module.css';

const Survey = () => {
    const { isInternalNavigation } = useNavigation();
    const location = useLocation();
    const surveyData = location && location.state ? location.state.surveyData : null;
    const { updateSurveyPostById } = usePosts();
    const navigate = useNavigate();
    const [answers, setAnswers] = useState({});
    const [currentIndex, setCurrentIndex] = useState(0);
    const [submitFlag, setSubmitFlag] = useState(false);

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', { replace: true });
        } else if (surveyData === null) {
            navigate('/', { replace: true });
        } else {
            let newAnswers = {};
            surveyData.questionIds.forEach(item => {
                newAnswers[item] = null;
            });
            setAnswers(newAnswers);
        }
    }, [navigate, isInternalNavigation, surveyData]);

    useEffect(() => {
        if (submitFlag && currentIndex === surveyData.questionIds.length - 1) {
            if (Object.values(answers).every(value => value !== null)) {
                updateSurveyPostById(surveyData.id, answers);
                navigate('/complete', { replace: true });
            } else {
                alert('잠시 후, 다시 시도해주세요');
            }
            setSubmitFlag(false); // Reset the flag after handling submit
        }
    }, [submitFlag, answers, currentIndex, surveyData, updateSurveyPostById, navigate]);

    const handleSubmit = (questionId, answer) => {
        const newAnswers = { ...answers, [questionId]: answer };
        setAnswers(newAnswers);
        if (currentIndex === surveyData.questionIds.length - 1) {
            setSubmitFlag(true); // Set the flag to true when it's time to submit
        } else {
            setCurrentIndex(currentIndex + 1);
        }
    }

    const handlePrev = (questionId, answer) => {
        setAnswers({ ...answers, [questionId]: answer });
        setCurrentIndex(currentIndex - 1);
    }

    const isEssay = (questionId) => questionId.includes('essay');

    const findQuestion = (type, questionId) => {
        if (type === 'essay') {
            return surveyData.essays.find(item => item.id === questionId);
        } else {
            return surveyData.multipleChoices.find(item => item.id === questionId);
        }
    }

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>설문조사</p>
            <div className="blank-30" />
            <div className={styles.content_container}>
                {isEssay(surveyData.questionIds[currentIndex]) ?
                    <SurveyEssay
                        data={findQuestion('essay', surveyData.questionIds[currentIndex])}
                        num={currentIndex}
                        leftNum={surveyData.questionIds.length - currentIndex - 1}
                        onClickPrevBtn={handlePrev}
                        onClickEvent={handleSubmit}
                        answers={answers}
                    />
                    :
                    <SurveyMultipleChoice
                        data={findQuestion('survey', surveyData.questionIds[currentIndex])}
                        num={currentIndex}
                        leftNum={surveyData.questionIds.length - currentIndex - 1}
                        onClickPrevBtn={handlePrev}
                        onClickEvent={handleSubmit}
                        answers={answers}
                    />
                }
            </div>
        </div>
    );
}

export default Survey;