import React, { useEffect, useState } from 'react';
import { useUser } from '../contexts/UserContext';
import { usePosts } from './../contexts/PostContext';
import SurveyPost from '../component/SurveyPost';
import VotePost from '../component/VotePost';
import styles from './MyPage.module.css';
import { IoIosMenu } from "react-icons/io";
import { MdHowToVote } from "react-icons/md";
import { RiSurveyLine } from "react-icons/ri";
import { useNavigation } from '../contexts/NavigationContext';
import { useLocation, useNavigate } from 'react-router-dom';

const MyPage = () => {
    const location = useLocation();
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    // useState
    const [isLoading, setIsLoading] = useState(false);
    const [currentCategory, setCurrentCategory] = useState(0);

    // Provider
    const { userData } = useUser();
    const { posts, loadMorePosts, isLastPost } = usePosts();
    const [participatedPostIds, setParticipatedPostIds] = useState([]);
    const [participatedPostNums, setParticipatedPostNums] = useState({ vote: 0, survey: 0 });

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            if (userData) {
                const newParticipatedPostIds = [];
                const newParticipatedPostNums = { vote: 0, survey: 0 }; // 새로 계산할 객체 생성

                userData.participatedPostInfos.forEach(item => {
                    const itemId = item.split('/')[0];
                    newParticipatedPostIds.push(itemId);

                    if (itemId.startsWith('vote-')) {
                        newParticipatedPostNums.vote += 1; // 직접 값을 증가
                    } else {
                        newParticipatedPostNums.survey += 1; // 직접 값을 증가
                    }
                });

                setParticipatedPostIds(newParticipatedPostIds); // 한 번에 상태 업데이트
                setParticipatedPostNums(newParticipatedPostNums); // 새로운 객체로 상태 업데이트
            }
        }

        setCurrentCategory(0);
    }, [navigate, isInternalNavigation, userData, location.search]); // 종속성 배열에 userData를 명시적으로 포함


    const handleLoadMorePosts = async () => {
        // 필터별로 불러오기
        if (isLoading) return;
        setIsLoading(true);

        try {
            await loadMorePosts(currentCategory, true);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    }

    const postsFiltered = () => {
        const myPosts = [];
        posts.forEach(item => {
            if (participatedPostIds.includes(item.id)) {
                myPosts.push(item);
            }
        });
        return myPosts.filter((item) => {
            if (!item) return false; // 빈 항목은 제거
            if (currentCategory === 0) {
                // 전체보기
                return true;
            } else if (currentCategory === 1 && item.type === 'vote') {
                // 투표 게시글만
                return true;
            } else if (currentCategory === 2 && item.type === 'survey') {
                // 설문조사 게시글만
                return true;
            }
            return false; // 그 외의 경우는 제외
        });
    };

    const handleCategory = (type) => {
        setCurrentCategory(type);
    }

    return (
        (posts && userData) ?
            <div className={styles.page}>
                <p className={styles.page_name}>프로필</p>
            <div className="blank-30"/>
                <div className={styles.profile_container}>
                    <img className={styles.profile} src={userData.profile} alt='profile' />
                    <div className='blank-20' />
                    <div className={styles.info_container}>
                        <p className={styles.name}>{`${userData.nickname}님`}</p>
                        <div className='blank-10' />
                        <p className={styles.post_info}>{`투표: ${participatedPostNums.vote}개 / 설문조사: ${participatedPostNums.survey}개`}</p>
                    </div>
                </div>
                <div className='blank-50' />
                <div className={styles.category_container}>
                    <button type='button' style={{ opacity: currentCategory === 0 ? 1 : 0.3 }} className={styles.category_item_btn} onClick={() => handleCategory(0)}>
                        <IoIosMenu size={24} color='var(--main-color)'/>
                        <div className='blank-5' />
                        <p className={styles.category_item_text}>전체보기</p>
                    </button>
                    <button type='button' style={{ opacity: currentCategory === 1 ? 1 : 0.3 }} className={styles.category_item_btn} onClick={() => handleCategory(1)}>
                        <MdHowToVote size={24} color='var(--main-color)' />
                        <div className='blank-5' />
                        <p className={styles.category_item_text}>투표</p>
                    </button>
                    <button type='button' style={{ opacity: currentCategory === 2 ? 1 : 0.3 }} className={styles.category_item_btn} onClick={() => handleCategory(2)}>
                        <RiSurveyLine size={24} color='var(--main-color)'/>
                        <div className='blank-5' />
                        <p className={styles.category_item_text}>설문조사</p>
                    </button>
                </div>
                <div className='blank-20' />
                {!(posts && userData) || postsFiltered().map((item) => {
                    if (!item) return null;

                    if (item.type === 'survey') {
                        return (
                            <SurveyPost
                                key={item.id}
                                surveyData={item}
                            />
                        );
                    } else {
                        return (
                            <VotePost
                                key={item.id}
                                voteData={item} />
                        );
                    }
                })}
                <div className='blank-20' />
                {isLastPost[currentCategory] === true || <button onClick={handleLoadMorePosts} className={styles.load_more_posts_btn} type="button">
                    <p className={styles.load_more_posts_btn_text} style={!isLoading ? {} : { display: 'none' }}>더 불러오기</p>
                    <div className={styles.loader} style={isLoading ? {} : { display: 'none' }}></div>
                </button>}
                <div className='blank-100'/>
            </div> :
            <div className='page_loading'></div>
    );
}

export default MyPage;
