export const calculateAge = (birthdate) => {
  const birthDate = new Date(birthdate);
  const today = new Date();
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();

  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }

  return age;
}

export const calculateAgeDistribution = (birthdateList) => {
  const ageDistribution = {
    '10대': 0,
    '20대': 0,
    '30대': 0,
    '40대': 0,
    '50대': 0,
    '60대': 0,
    '70대': 0,
    '80대+': 0,
  };

  birthdateList.forEach(birthdate => {
    const age = calculateAge(birthdate);
    const ageGroup = Math.floor(age / 10) * 10;
    if (ageGroup >= 10 && ageGroup < 80) {
      ageDistribution[`${ageGroup}대`] += 1;
    }else if(age<10){
      ageDistribution['10대'] += 1;
    }else{
      ageDistribution['80대+'] += 1;
    }
  });

  return ageDistribution;
}