import React from "react";
import styles from "./ChoiceType.module.css";
import { FaPenFancy } from "react-icons/fa";
import { FaCheckCircle } from "react-icons/fa";

const ChoiceType = ({ notifyParent }) => {
    return (
        <div className={styles.container}>
            <p className={styles.title}>타입 선택</p>
            <div className="blank-50" />
            <div className={styles.type_container}>
                <div className={styles.type_btn} type="button"
                    onClick={() => notifyParent("essay")}>
                    <div className={styles.type_btn_container}>
                        <FaPenFancy color='rgba(10, 10, 10, 1)' size={28} />
                        <div className="blank-20" />
                        <p className={styles.type_btn_text}>주관식</p>
                    </div>

                </div>
                <div className="blank-20" />
                <div className={styles.type_btn} type="button"
                    onClick={() => notifyParent("multipleChoice")}>
                    <div className={styles.type_btn_container}>
                        <FaCheckCircle color='rgba(10, 10, 10, 1)' size={28} />
                        <div className="blank-20" />
                        <p className={styles.type_btn_text}>객관식</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChoiceType;
