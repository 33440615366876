import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export const useNavigation = () => useContext(NavigationContext);

export const NavigationProvider = ({ children }) => {
  const [isInternalNavigation, setIsInternalNavigation] = useState(false);

  return (
    <NavigationContext.Provider value={{ isInternalNavigation, setIsInternalNavigation }}>
      {children}
    </NavigationContext.Provider>
  );
};