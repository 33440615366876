import React, { useEffect, useState } from "react";
import styles from "./SurveyIndividualView.module.css";
import { useLocation, useNavigate } from 'react-router-dom';
import { calculateAgeGroup } from '../../model/util/calculateAgeGroup';
import IndividualEssayView from '../../component/data_analytics/IndividualEssayView';
import IndividualMultipleChoiceView from "../../component/data_analytics/IndividualMultipleChoiceView";
import { useNavigation } from "../../contexts/NavigationContext";

const SurveyIndividualView = () => {
    const { isInternalNavigation } = useNavigation();
    const navigate = useNavigate();

    const location = useLocation();
    const data = location.state ? location.state : null;

    const [, setSurvey] = useState({});
    const [questions, setQuestions] = useState([]);
    const [answerObjs, setAnswerObjs] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [totalAnswerNum, setTotalAnswerNum] = useState(0);

    useEffect(() => {
        if (!isInternalNavigation) {
            navigate('/', {
                replace: true
            });
        } else {
            if (!data || !data.survey || !data.answers) return;

            const newAnswerObjs = data.answers.map(answer => JSON.parse(answer));
            const newQuestions = [];
            const newSurvey = data.survey;
            newSurvey.questionIds.forEach((questionId) => {
                if (questionId.startsWith('essay')) {
                    newQuestions.push(newSurvey.essays.find(essay => essay.id === questionId));
                } else {
                    newQuestions.push(newSurvey.multipleChoices.find(multipleChoice => multipleChoice.id === questionId));
                }
            })

            setSurvey(data.survey);
            setQuestions(newQuestions);
            setAnswerObjs(newAnswerObjs);
            setTotalAnswerNum(newAnswerObjs.length);
        }
    }, [navigate, isInternalNavigation, data]);

    // handle Click
    const handleClick = (destination) => {
        if (destination === "prev") {
            if (currentIndex === 0) return;
            setCurrentIndex(currentIndex - 1);
        } else {
            if (currentIndex === totalAnswerNum - 1) return;
            setCurrentIndex(currentIndex + 1);
        }
    }

    return (
        <div className={styles.page}>
            <p className={styles.page_name}>개별 보기</p>
            <div className="blank-30"/>
            {answerObjs.length > 0 && currentIndex >= 0 && currentIndex < answerObjs.length ? (
                <div className={styles.container}>
                    <div className={styles.profile_container}>
                        <img className={styles.profile_image} src={answerObjs[currentIndex].userProfile} alt='profile' />
                        <div className='blank-20' />
                        <div className={styles.profile_info_container}>
                            <p className={styles.profile_info_name}>{`${answerObjs[currentIndex].userNickname}님`}</p>
                            <div className='blank-10' />
                            <p className={styles.profile_info_info}>{`${calculateAgeGroup(answerObjs[currentIndex].userBirthday)} / ${answerObjs[currentIndex].userSex === 0 ? '남' : '여'} / ${answerObjs[currentIndex].userAddress}`}</p>
                        </div>
                    </div>
                    <div className="blank-20" />
                    <div className={styles.navigate_btn_container}>
                        <button type="button" className={styles.prev_btn} onClick={() => { handleClick("prev") }}>
                            이전
                        </button>
                        <p className={styles.page_num}>
                            {`${currentIndex + 1}/${totalAnswerNum}`}
                        </p>
                        <button type="button" className={styles.next_btn} onClick={() => { handleClick("next") }}>
                            다음
                        </button>
                    </div>
                    <div className="blank-20" />
                    <div className={styles.questions_container}>
                        {questions.map((question, i) => {
                            const newAnswers = [];
                            answerObjs.forEach((answerObj) => {
                                newAnswers.push(answerObj.answer[question.id]);
                            });
                            if (question.type === "essay") {
                                return <IndividualEssayView key={question.id} currentNum={i + 1} essay={question}
                                    essayAnswer={answerObjs[currentIndex].answer[question.id]} />
                            } else {
                                return <IndividualMultipleChoiceView key={question.id} currentNum={i + 1}
                                    multipleChoice={question} multipleChoiceAnswers={newAnswers}
                                    myMultipleChoiceAnswer={answerObjs[currentIndex].answer[question.id]} />
                            }
                        })}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default SurveyIndividualView;