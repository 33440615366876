// ✅ GPT 코드리뷰
import React, { createContext, useState, useContext, useEffect, useMemo } from 'react';
import { Response } from './../model/response/response';
import { User } from '../model/response/user';
import { signInWithEmailAndPassword, signOut, deleteUser } from 'firebase/auth';
import { auth } from '../firebase';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const response = useMemo(() => new Response(), []);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const uid = localStorage.getItem('uid');

        try {
            const fetchUserData = async () => {
                if (uid !== null) {
                    const email = `${uid}@gmail.com`;
                    const password = 'ehdtjskaqnr'; // 실제 비밀번호를 사용해야 합니다.
                    await signInWithEmailAndPassword(auth, email, password);

                    const newUser = await response.readUser(uid);
                    if (newUser !== null) {
                        setUserData(User.fromJson(newUser));
                    } else {
                        localStorage.removeItem('uid');
                    }
                }
            };

            fetchUserData();
        } catch (e) {
            console.error(e);
            localStorage.removeItem('uid');
        }
    }, [response]);

    const updateUserData = (newData) => {
        setUserData({ ...userData, ...newData });
        response.updateUser(newData.uid, newData);
    };

    const signOutUserData = async () => {
        try {
            await signOut(auth);
            setUserData(null);
            localStorage.removeItem('uid');
        } catch (e) {
            console.error(e);
        }
    }

    const deleteUserData = async () => {
        try {
            const user = auth.currentUser;
            await deleteUser(user);
            setUserData(null);
            localStorage.removeItem('uid');
        } catch (e) {
            console.error(e);
        }
    }

    const initializeUserData = (newData) => {
        setUserData({ ...userData, ...newData });
    };

    return (
        <UserContext.Provider value={{ userData, updateUserData, initializeUserData, signOutUserData, deleteUserData }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
